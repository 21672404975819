
import {  ModuleKeys } from './constants'
export const NavbarData = [
    { id: "dashboard", name: "DashBoard", path: "/dashboard" },
    {
        id: "marketstats", name: "Marketstats", path: "/#", alignment: 'col-12',
        submenu: [
            {
                id: "ms_eq", name: "EQUITY", path: "/#",
                submenu: [
                    {
                        id: "ms_eq_market", name: "MARKET", path: "/#",
                        submenu: [
                            { id: "ms_eq_market_summary", name: "SUMMARY", path: "/home/ms/eq/mkt/summary", disabled: true },
                            { id: "ms_eq_market_sectors", name: "SECTORS", path: "/home/ms/eq/mkt/sectors", disabled: true },
                         //   { id: "ms_eq_market_screeners", name: "SCREENERS", path: "/home/ms/eq/mkt/screeners", disabled: true },
                            { id: "ms_eq_market_advdec", name: "TOP MOVERS", path: "/home/ms/eq/mkt/advancedecline" },
                         //   { id: "ms_eq_market_fiidii", name: "FII/DII ACTIVITY", path: "/home/ms/eq/mkt/fiidiiactivity", disabled: true },
                            // { id: "ms_eq_market_unusual", name: "UNUSUAL ACTIVITY", path: "/#", disabled: true },
                            // { id: "ms_eq_market_intradaydeals", name: "INTRADAY DEALS", path: "/home/ms/eq/mkt/intradaydeals", disabled: true },
                        ]
                    },
                    { id: "ms_eq_overview", name: "OVERVIEW", path: "/home/ms/eq/overview" },
                    { id: "ms_eq_heatmap", name: "HEATMAP", path: "/home/ms/eq/heatmap" },
                    { id: "ms_eq_indices", name: "INDICES", path: "/home/ms/eq/indices" },
                    // { id: "ms_eq_calender", name: "CALENDAR", path: "/home/ms/eq/calender" },
                ]
            },
           /*  {
                id: "ms_nfo", name: "FnO", path: "/#", alignment: 'col-12', disabled: true,
                submenu: [
                    {
                        id: "ms_nfo_market", name: "MARKET", path: "/#",
                        submenu: [
                            { id: "ms_nfo_market_summary", name: "SUMMARY", path: "/home/ms/fno/mkt/summary" },
                            { id: "ms_nfno_market_sectors", name: "SECTORS", path: "/home/ms/fno/mkt/sectors" },
                            { id: "ms_nfno_market_screeners", name: "SCREENERS", path: "/home/ms/fno/mkt/screeners" },
                            // { id: "ms_nfo_market_advdec", name: "ADVANCE/DECLINE", path: "/home/ms/fno/mkt/advancedecline" },
                            { id: "ms_nfno_market_unusual", name: "UNUSUAL ACTIVITY", path: "/home/ms/fno/mkt/unusual" },
                        ]
                    },
                    { id: "ms_nfo_overview", name: "OVERVIEW", path: "/home/ms/fno/overview" },
                    // { id: "ms_nfo_heatmap", name: "HEATMAP", path: "/#" },
                    // { id: "ms_nfo_strategies", name: "STRATEGIES", path: "/#" },
                ]
            }, */
            {
                id: "ms_cur", name: "CURRENCY", path: "/#", disabled: true,
                submenu: [
                    // { id: "ms_cur_summary", name: "SUMMARY", path: "/#" },
                    { id: "ms_cur_overview", name: "OVERVIEW", path: "/home/cur/overview" }
                ]
            },
            {
                id: "ms_com", name: "COMMODITY", path: "/#", disabled: true,
                submenu: [
                    //  { id: "ms_com_summary", name: "SUMMARY", path: "/#" },
                    { id: "ms_com_overview", name: "OVERVIEW", path: "/home/com/overview" }
                ]
            }
        ]
    },
    { id: "charts", name: "CHARTS", path: "/charts" },
   // {
     //   id: "research", name: "RESEARCH", path: "/dashboard", alignment: 'research-submenu',
        //     submenu: [
        //         {
        //             id: "rsrch_sc", name: "SCREENERS", path: "/#",
        //             submenu: [
        //                 { id: "rsrch_sc_ic", name: "INCOME", path: "/#" },
        //                 { id: "rsrch_sc_ql", name: "QUALITY", path: "/#" },
        //                 { id: "rsrch_sc_vl", name: "VALUE", path: "/#" },
        //                 { id: "rsrch_sc_gr", name: "GROWTH", path: "/#" },
        //             ]
        //         },
        //         {
        //             id: "rsrch_ibc", name: "IB CALLS", path: "/#",
        //             submenu: [
        //                 { id: "rsrch_ibc_eq", name: "EQUITY", path: "/#" },
        //                 { id: "rsrch_ibc_fno", name: "FnO", path: "/#" },
        //                 { id: "rsrch_ibc_cur", name: "CURRENCY", path: "/#" },
        //                 { id: "rsrch_ibc_com", name: "COMMODITY", path: "/#" },
        //             ]
        //         },
        //     ]
  //  },
    // {
    //     id: "news", name: "NEWS", path: "/home/news", alignment: '',
    //     submenu: [
    //         { id: "news_top", name: "TOP NEWS", path: "/home/news/hotnews", module: ModuleKeys.HOT_NEWS },
    //       //  { id: "news_cur", name: "CURRENCY", path: "/home/news/currency", module: ModuleKeys.CURRENCY_NEWS },
    //        // { id: "news_com", name: "COMMODITY", path: "/home/news/commodity", module: ModuleKeys.COMMODITY_NEWS },
    //         { id: "news_intrnl", name: "INTERNATIONAL NEWS", path: "/home/news/international", module: ModuleKeys.INTERNATIONAL_NEWS },
    //         { id: "news_economic", name: "ECONOMIC NEWS", path: "/home/news/economic", module: ModuleKeys.ECONOMIC_NEWS },
    //        // { id: "news_comp", name: "COMPANY NEWS", path: "/home/news/company", module: ModuleKeys.COMPANY_NEWS },
    //        // { id: "news_sctr", name: "SECTOR NEWS", path: "/home/news/sector", module: ModuleKeys.SECTOR_NEWS },
    //         { id: "news_corp", name: "CORPORATE NEWS", path: "/home/news/corporate", module: ModuleKeys.CORPORATE_NEWS },
    //        // { id: "news_dc", name: "DISCUSSION CLOUD", path: "/home/news/discussion", module: ModuleKeys.DISCUSSION_CLOUD },
    //     ]
    // },
    // {
    //     id: "mutualfund", name: "Mutual Funds", path: "/#", alignment: 'mt-fund-submenu', disabled: true,
    //     submenu: [
    //         {
    //             id: "mf_dash", name: "MY DASHBOARD", path: "/#",
    //             submenu: [
    //                 { id: "mf_dash_recom", name: "RECOMMENDED FUNDS", path: "/#" }
    //             ]
    //         },
    //         {
    //             id: "mf_op", name: "ORDER PLACEMENT", path: "/#",
    //             submenu: [
    //                 { id: "mf_op_purchase", name: "PURCHASE", path: "/#" },
    //                 { id: "mf_op_redeem", name: "REDEEM", path: "/#" },
    //                 { id: "mf_op_switch", name: "SWITCH", path: "/#" }
    //             ]
    //         },
    //         {
    //             id: "mf_sip", name: "SIP", path: "/#",
    //             submenu: [
    //                 { id: "mf_sip_start", name: "START SIP", path: "/#" },
    //                 { id: "mf_sip_regrprt", name: "SIP REGISTRATION REPORT", path: "/#" },
    //                 { id: "mf_sip_mrprt", name: "SIP MANDATE REPORT", path: "/#" },
    //                 { id: "mf_sip_dwnld", name: "DOWNLOAD MANDATE FORM", path: "/#" }
    //             ]
    //         },
    //         { id: "mf_pf", name: "MF PORTFOLIO", path: "/#" },
    //         { id: "mf_rprt", name: "MF REPORTS", path: "/#" },
    //         { id: "mf_rsrch", name: "MF RESEARCH", path: "/#" },
    //         { id: "mf_nfo", name: "NFO", path: "/#" }
    //     ]
    // },
    {
        id: "products", name: "PRODUCTS", path: "/#", alignment: '',
        submenu: [
            // { id: "prdct_ofs", name: "OFS", path: "/home/ofs" },
            // { id: "prdct_sep", name: "SEP", path: "/home/sep" },
            // { id: "prdct_bo", name: "BASKET ORDER", path: "/home/basketorder" },
            { id: "prdct_ipo", name: "IPO", path: "/home/ipo" },
            // { id: "prdct_track", name: "PORTFOLIO TRACKER", path: "#" },
            { id: "portfolioTracker", name: "PROFIT & LOSS REPORT", path: "/home/portfolioTracker"},
            { id: "prdct_bo", name: "BASKET ORDER", path: "/home/basketorder"},
            { id: "portfolioTrackerDion", name: "PROFIT & LOSS REPORT NEW", path: "/home/portfolioTrackerDion"}
        ]
    },
    {
        id: "portfolio", name: "PORTFOLIO", path: "/#", alignment: '',
            submenu: [
               
                { id: "portfolio1", name: "DP HOLDINGS", path: "/home/dpholdings" },
                { id: "PoaPledge", name: "PLEDGE/UNPLEDGE STOCKS", path: "/home/PoaPledge" },
                { id: "ExchangePledge", name: "EXCHANGE PLEDGE/UNPLEDGE STOCKS", path: "/home/ExchangePledge" } 
            ]
    },
    {
        id: "reports", name: "REPORTS", path: "/reports", alignment: '',
        submenu: [
            //{ id: "rprt_eq", name: "EQUITY", path: "/home/equity" },
            //{ id: "rprt_fno", name: "FNO", path: "/home/fno" },
            { id: "rprt_dcn", name: "DIGITAL CONTRACT NOTE", path: "/home/digitalcontractnote" },
            // { id: "rprt_bko", name: "BACKOFFICE REPORTS", path: "/home/backofficereport" },
            {
                id: "rprt_bko", name: "BACKOFFICE REPORTS", path: "/#", submenu: [
                    { id: "bko_crt", name: "CURRENT FY", path: "/home/backofficereportcurrent" },
                    // { id: "bko_pre", name: "PREVIOUS FY", path: "#" },
                    //{ id: "bko_pre", name: "PREVIOUS FY", path: "/home/backofficereportprevfinancialyear" },
                ]
            },
            {
                id: "rprt_prt", name: "PORTFOLIO REPORTS", path: "/#", submenu: [
                    { id: "prt_ctgn", name: "CAPITAL GAIN (YEAR WISE)", path: "/home/report/capitalgain" },
                    { id: "prt_gs", name: "GAIN SUMMARY", path: "/home/report/gainsummary" }
                ]
            },
            // { id: "rprt_ldgr", name: "CLIENT LEDGER", path: "/home/ledger" },
            // { id: "rprt_sdwt", name: "SCRIP/DATE WISE TRANSACTION", path: "/home/transaction" },
            // { id: "rprt_dh", name: "DEMAT HOLDINGS", path: "/home/dematholdings" },
            // { id: "rprt_cm", name: "CLIENT MASTER", path: "/home/clientmaster" },
            // { id: "rprt_sr", name: "STT REPORT", path: "/home/stt" },
            // { id: "rprt_pbr", name: "PRE-PAID BROKERAGE REPORT", path: "/home/brokerage" },


            // {
            //     id: "rprt_lim", name: "LIMITS", path: "/#",
            //     submenu: [
            //         { id: "rprt_lim_co", name: "CURRENT OBLIGATIONS", path: "/#" },
            //         { id: "rprt_lim_po", name: "PREVIOUS OBLIGATIONS", path: "/#" },
            //         { id: "prprt_lim_ss", name: "SHORTAGES SUMMARY", path: "/#" },
            //         { id: "rprt_lim_com", name: "COMMODITY", path: "/#" },
            //     ]
            // },
            // { id: "rprt_tb", name: "TRADE BILLS", path: "/#" },
            // { id: "rprt_tr", name: "TRANSACTION REPORTS", path: "/#" },
            // { id: "rprt_mr", name: "MTF REPORTS", path: "/#" },
            // {
            //     id: "rprt_sr", name: "SEP REPORTS", path: "/#",
            //     submenu: [
            //         { id: "rprt_sr_srr", name: "SEP REGISTRATION REPORT", path: "/home/sepregistrationreport" },
            //         { id: "rprt_sr_ser", name: "SEP EXECUTION REPORT", path: "/home/sepexecutionreport" },
            //     ]
            // },
            // { id: "rprt_mr", name: "MARGIN REPORTS", path: "/#" },
            // { id: "rprt_pyr", name: "PREVIOUS YEAR REPORTS", path: "/#" },
        ]
    },
    // {
    //     id: "switch", name: "Switch to Classic Mode", path: "/#", alignment: 'dropdown-menu-right'
    // },
    {
        id: "info", name: "INFO", path: "/#", alignment: 'dropdown-menu-right',
        submenu: [
            { id: "info_about", name: "ABOUT US", path: "/home/aboutus" },
            { id: "info_help", name: "HELP", path: "/#" },
            { id: "info_con", name: "CONTACT US", path: "/home/contactus" },
            { id: "info_exch", name: "EXCHANGE MESSAGES", path: "/home/exchangemessages" },
            { id: "info_imp", name: "IMPORTANT LINKS", path: "/home/importantlinks" },
            { id: "info_cdsl", name: "CDSL EVOTING", path: "/#" },
            { id: "info_log", name: "LOGIN ALERT", path: "/home/loginalert" },
            { id: "info_dp", name: "DP CHARGES SCHEDULE", path: "/#" },
            { id: "info_corp", name: "CORPORATE ACTION REPORT", path: "/home/corporateaction" },
            { id: "info_bhav", name: "BHAV COPY", path: "/home/bhavcopy" },
            { id: "info_mar", name: "MARKET STATUS & TIMINGS", path: "/home/markettimings" },
            { id: "info_ter", name: "TERMS & CONDITIONS", path: "/home/termsandcondition" },
            // { id: "info_faq", name: "FAQ", path: "/home/faq" }, removed for mock need to uncomment after mock
            // { id: "info_lan", name: "LANDING", path: "/#" },
        ]
    },

];