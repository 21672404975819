import React, { Component } from "react";
import TabbarBaseWidget from "../base/TabbarBaseWidget";
import NTPledgeDataMobile from "./NTPledgeDataMobile";
import NTUnpledgeMobileData from "./NTUnpledgeMobileData";
import { progressBar } from "../../controls/progress";
import Dropdown from "../../controls/Dropdown";
import { decryptParamNT } from '../../actions/login/preloginrequests';
import {
  PledgeOptions,
  commonDropDownType,
  localStorageKeys,
} from "../../common/constants";
import {
  getCookie,
  getItemByKey,
  setCookie,
  maskUserData,
  setItemByKey,
} from "../../common/utils";
import { getNTPledgeUnpledge } from "../../actions/NTPoaPledge/NTPledgeUnpledgeRequest";
import { exchangeNTPledgeUnpledge } from "../../actions/NTExchangePledge/NTExchangePledgeUnpledgeRequest";
import NTExchangePledgeData from "./../NTExchangePledge/NTExchangePledgeData";
import NTExchangeUnpledgeData from "./../NTExchangePledge/NTExchangeUnpledgeData";

class NTPoaPledgeMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: ["PLEDGE", "UNPLEDGE", "EXCHANGE PLEDGE", "EXCHANGE UNPLEDGE"],
      data: [],
      DematAccList: [],
      selectedsegment: "PLEDGE",
      selectedAcount: "",
      DematDetails: [],
      PledgerdpName: "",
      PledgerMobile: "",
      PledgerName: "",
      SelectedDematAcc: "",
      Pledgeename: "",
      PledgeeDematAc: "",
      error: "",
      inProgress: false,
      sortColumnId: "",
      sortType: "",
      sortDataType: "",
      apiStatus: "",
      rePledge: [],
      reUnpledge: [],
      exErr: [],
      isPIB: true,
      isFlagEnabled: true,
    };
  }

  onTabClicked(tabName) {
    switch (tabName) {
      case "PLEDGE":
        this.setStyle("block");
        document.getElementsByClassName("dp-tab-content")[0].style =
          "display:block";
        document.getElementById("paraText").innerHTML =
          "Select scrip to pledge to Dhani Stocks Limited";
        this.setState({ selectedsegment: "PLEDGE" }, () =>
          this.requestNTPledgeUnpledge()
        );
        break;
      case "UNPLEDGE":
        this.setStyle("block");
        document.getElementsByClassName("dp-tab-content")[0].style =
          "display:block";
        document.getElementById("paraText").innerHTML =
          "Select scrip to pledge to Dhani Stocks Limited";
        this.setState({ selectedsegment: "UNPLEDGE" }, () =>
          this.requestNTPledgeUnpledge()
        );
        break;
      case "EXCHANGE PLEDGE":
        this.setStyle("none");
        this.setState({ selectedsegment: "EXCHANGE PLEDGE" }, () =>
          this.requestNTExchangePledgeUnpledge()
        );
        break;
      case "EXCHANGE UNPLEDGE":
        this.setStyle("none");
        this.setState({ selectedsegment: "EXCHANGE UNPLEDGE" }, () =>
          this.requestNTExchangePledgeUnpledge()
        );
        break;
      default:
        this.setState({ selectedsegment: "PLEDGE" }, () =>
          this.requestNTPledgeUnpledge()
        );
        break;
    }
  }
  // shorting-----
  sortSymbols(eventId, sortTemp, sortDataType) {
    this.setState({
      sortColumnId: eventId,
      sortType: sortTemp,
      sortDataType: sortDataType,
    });
  }

  setStyle(state) {
    let div = document.getElementsByClassName("row mobileTopDropdown")[0];
    if (div !== undefined) {
      div.style = "display:" + state;
    }
  }

  componentDidMount() {
    var requestData = {
        request: {
            data: {
                data: getItemByKey('PARTNER_DATA_PARAM')
            }
        }
    }
    decryptParamNT(requestData, this.ssoSuccessCallback.bind(this), this.ssoFailedCallback.bind(this));
    setCookie("REDIRECT_TO", null, 0);
    this.requestNTPledgeUnpledge();
    var accountDet = JSON.parse(getItemByKey(localStorageKeys.ACCOUNT_ID));
    this.setState({
      Mobileclientid: accountDet ? accountDet.accID : "",
    });
    console.log("source ====");
    console.log(this.props.source);
    // this.loadDetailsForDematAcc();
  }

  ssoSuccessCallback(responseData) {
    if(responseData.response.data.result=="SUCCESS")
    {   
        this.setState({inProgress:false,  userId:responseData.response.data.userId, source:responseData.response.data.source, appkey : responseData.response.data.appkey });
    }
  }

  ssoFailedCallback(error) { 
    this.setState({
        inProgress: false,
        error: error.message,
    });      

    setCookie('REDIRECT_TO', null, 0);
    setCookie('PARTNER_DATA_PARAM', null, 0);
    setItemByKey('PARTNER_DATA_PARAM', null);
  }

  loadDetailsForDematAcc() {
    window.setTimeout(() => {
      var selectDemat = document.getElementById("selectDematId").innerText;
      var selectedDemat = document.getElementById("selectDematId");
      console.group(selectedDemat);
      var selctDemat = this.state.data.dematData.filter(
        (x) => x.pledgerDemat === selectDemat
      );

      selectedDemat.lastChild.children[0].innerHTML =
        selectedDemat.lastChild.children[0].innerHTML +
        '<span style="visibility:hidden;" id="selectedDematApiStatus">' +
        selctDemat[0].apiStatus +
        '</span><span style="visibility:hidden;" id="selectedDematdpName">' +
        selctDemat[0].dpName +
        "</span>";
    }, 500);
  }

  requestNTExchangePledgeUnpledge() {
    let appKey =
      getItemByKey(localStorageKeys.PIB_APP_KEY) !== ""
        ? JSON.parse(getItemByKey(localStorageKeys.PIB_APP_KEY))
        : "";
    var requestData = {
      request: {
        data: {
          reqType: "EXCHANGE_PLEDGE_UNPLEDGE",
          userId: window.sessionStorage.getItem("userId"),
          appKey: appKey,
        },
      },
    };
    this.setState({
      inProgress: true,
      error: "",
      data: [],
      selectedAcount: "",
      apiStatus: "",
      apiKey: "",
    });
    exchangeNTPledgeUnpledge(
      requestData,
      this.parseExPledgeUnpledgeResponse.bind(this, this.state.selectedsegment),
      this.parseExPledgeUnpledgeError.bind(this, this.state.selectedsegment)
    );
  }

  parseExPledgeUnpledgeResponse(selectedsegment, responseData) {
    console.log(responseData);
    if (responseData.response.data.state === "DISABLED") {
      this.setState({ isFlagEnabled: false, inProgress: false });
      document.getElementById("paraText").innerHTML =
        "<h2><strong>This feature will come soon !!</strong></h2>";
      document.getElementsByClassName("dp-tab-content")[0].style =
        "display:none";
    } else {
      let resExErr = responseData.response.data.exErr;
      let exErr = [];
      if (resExErr.includes("ALL")) {
        exErr.push("ALL");
      } else {
        resExErr.includes("NSE") || resExErr.includes("BSE")
          ? exErr.push("CM")
          : "";
        resExErr.includes("NFO") ? exErr.push("FO") : "";
        resExErr.includes("CDS") ? exErr.push("CDS") : "";
      }
      this.setState({
        rePledge: responseData.response.data.ExchangePledgeUnpledge.rePledge,
        reUnpledge:
          responseData.response.data.ExchangePledgeUnpledge.reUnpledge,
        exErr: exErr,
      });
      switch (selectedsegment) {
        case "EXCHANGE PLEDGE":
          if (this.state.rePledge.length === 0) {
            this.setState({
              error: "No data Avaliable",
            });
          } else {
            this.setState({
              inProgress: false,
            });
            this.setStyle("none");
          }
          break;
        case "EXCHANGE UNPLEDGE":
          if (this.state.reUnpledge.length === 0) {
            this.setState({
              error: "No data Avaliable",
            });
          } else {
            this.setState({
              inProgress: false,
            });
            this.setStyle("none");
          }
          break;
      }

      let div = document.getElementsByClassName("fillheight fillwidth")[0];
      let error = document.getElementsByClassName("error-holder")[0];
      if (div !== undefined) {
        div.style = "color:black!important";
      }
      if (error !== undefined) {
        error.style = "color:black";
      }
    }
  }

  parseExPledgeUnpledgeError(selectedsegment, error) {
    if (selectedsegment === this.state.selectedsegment) {
      this.setState({
        inProgress: false,
        error: error.message,
      });
    }
  }

  requestNTPledgeUnpledge() {
    var accountDet = JSON.parse(getItemByKey(localStorageKeys.ACCOUNT_ID));
    var requestData = {
      request: {
        data: {
          tab: this.state.selectedsegment,
          source: this.props.source,
          clientId: accountDet ? accountDet.accID : "",
        },
      },
    };
    this.setState({
      inProgress: true,
      error: "",
      data: [],
      DematAccList: [],
      selectedAcount: "",
      DematDetails: [],
      PledgerdpName: "",
      PledgerMobile: "",
      PledgerName: "",
      SelectedDematAcc: "",
      Pledgeename: "",
      PledgeeDematAc: "",
      apiStatus: "",
    });
    getNTPledgeUnpledge(
      requestData,
      this.parsePledgeUnpledgeResponse.bind(this, this.state.selectedsegment),
      this.parsePledgeUnpledgeError.bind(this, this.state.selectedsegment)
    );
  }
  parsePledgeUnpledgeResponse(selectedsegment, responseData) {
    var DematAccList = responseData.response.data.dematData.map((data) => {
      data.name = data.pledgerDemat;
      data.data = data.data.map((paydata) => {
        paydata.name = paydata.gatewaySource;
        return paydata;
      });
      return data;
    });
    var selectedAcount = {};
    if (DematAccList && DematAccList.length > 0) {
      selectedAcount = DematAccList[0];
      this.setState(
        {
          inProgress: false,
          data: responseData.response.data,
          DematAccList: DematAccList,
          DematDetails:
            DematAccList && DematAccList.length > 0 ? DematAccList[0].data : [],
          selectedAcount: selectedAcount,
          PledgerName:
            DematAccList && DematAccList.length > 0
              ? DematAccList[0].pledgerName
              : "",
          PledgerMobile:
            DematAccList && DematAccList.length > 0
              ? DematAccList[0].mobile
              : "",
          PledgerdpName:
            DematAccList && DematAccList.length > 0
              ? DematAccList[0].dpName
              : "",
          SelectedDematAcc:
            DematAccList && DematAccList.length > 0 ? DematAccList[0].name : "",
          Pledgeename:
            DematAccList && DematAccList.length > 0
              ? DematAccList[0].pledgeeName
              : "",
          PledgeeDematAc:
            DematAccList && DematAccList.length > 0
              ? DematAccList[0].pledgeeDematId
              : "",
          apiStatus:
            DematAccList && DematAccList.length > 0
              ? DematAccList[0].apiStatus
              : "",
        },
        () => {
          this.dematselectacc.setSelected(
            this.state.selectedAcount
              ? this.state.selectedAcount.pledgerDemat
              : " "
          );
        }
      );
      window.setTimeout(() => {
        document.getElementById("selectDematId").innerText =
          this.state.SelectedDematAcc;
        var selectedDemat = document.getElementById("selectDematId");
        selectedDemat.innerHTML =
          selectedDemat.innerHTML +
          '<span style="visibility:hidden;" id="selectedDematApiStatus">' +
          this.state.apiStatus +
          '</span><span style="visibility:hidden;" id="selectedDematdpName">' +
          this.state.PledgerdpName +
          "</span>";
      }, 100);
    } else {
      this.setState({
        inProgress: false,
      });
    }
    let error = document.getElementsByClassName("error-holder")[0];
    if (error !== undefined) {
      error.style = "color:black";
    }
  }

  parsePledgeUnpledgeError(selectedsegment, error) {
    if (selectedsegment === this.state.selectedsegment) {
      this.setState({
        inProgress: false,
        error: error.message,
      });
    }
  }

  // on dropdown change
  onAcckSelected(selected, index) {
    this.setState(
      {
        PledgerName: this.state.DematAccList[index].pledgerName,
        PledgerMobile: this.state.DematAccList[index].mobile,
        PledgerdpName: this.state.DematAccList[index].dpName,
        DematDetails: this.state.DematAccList[index].data,
        SelectedDematAcc: this.state.DematAccList[index].name,
        PledgeeDematAc: this.state.DematAccList[index].pledgeeDematId,
        Pledgeename: this.state.DematAccList[index].pledgeeName,
        apiStatus: this.state.DematAccList[index].apiStatus,
      },
      () => {}
    );
    window.setTimeout(() => {
      document.getElementById("selectDematId").innerText =
        this.state.SelectedDematAcc;
      var selectedDemat = document.getElementById("selectDematId");
      selectedDemat.innerHTML =
        selectedDemat.innerHTML +
        '<span style="visibility:hidden;" id="selectedDematApiStatus">' +
        this.state.apiStatus +
        '</span><span style="visibility:hidden;" id="selectedDematdpName">' +
        this.state.PledgerdpName +
        "</span>";
    }, 100);
    var symblArr = document.getElementsByClassName("pledgeCheckbox");
    for (var i = 0; i < symblArr.length; i++) {
      document.getElementById(symblArr[i].id).checked = true;
    }

    document.getElementById("submitRequest").disabled = false;
    document.getElementById("unselectAll").disabled = false;
    // document.getElementById('selectDematId').innerText = '';
  }

  render() {
    var errorHolder = (
      <div className="error-holder fillwidth">
        <span> {this.state.error} </span>
      </div>
    );
    return (
      <div
        className="fillheight"
        style={{ background: "#fff", overflow: "hidden" }}
      >
        <div
          class="container pledgemobiledata"
          style={{ padding: "0px", height: "100%" }}
        >
          <div class="row">
            <div class=" header col-md-12">
              <h5> Securities Pledge-Unpledge / Exchange Pledge-Unpledge</h5>
            </div>
            <TabbarBaseWidget
              tabs={this.state.tabs}
              onTabClicked={this.onTabClicked.bind(this)}
            >
              <p
                id="paraText"
                style={{
                  color: "#000",
                  borderTop: "1px solid #3a3a3a",
                  marginTop: "15px",
                  borderBottom: "1px solid #3a3a3a",
                  padding: "10px 0px",
                }}
              >
                Select scrip to pledge to Dhani Stocks Limited
              </p>
              <div className="row contentpanel dp-tab-content">
                {this.state.error ? (
                  errorHolder
                ) : (
                  <div className="fillheight fillwidth">
                    <div
                      className="row mobileTopDropdown"
                      style={{
                        border: "1px solid #cdcdcd",
                        padding: "4px 15px",
                        borderRadius: "4px",
                      }}
                    >
                      <Dropdown
                        ref={(dematselectacc) =>
                          (this.dematselectacc = dematselectacc)
                        }
                        id="selectDematId"
                        dropdownType={commonDropDownType.DEFAULT}
                        list={this.state.DematAccList}
                        onSelectionChanged={this.onAcckSelected.bind(this)}
                      />
                    </div>
                    {this.state.inProgress
                      ? progressBar()
                      : this.state.data.error
                      ? this.state.errorHolder
                      : [
                          this.state.selectedsegment === "PLEDGE" ? (
                            <NTPledgeDataMobile
                              data={this.state.DematDetails}
                              source={this.state.source}
                              appkey={this.state.appkey}
                              dematAcc={this.state.SelectedDematAcc}
                              selecttab={this.state.selectedsegment}
                              callback={this.sortSymbols.bind(this)}
                              requestNTPledgeUnpledge={this.requestNTPledgeUnpledge.bind(
                                this
                              )}
                              sortColumnId={this.state.sortColumnId}
                              sortType={this.state.sortType}
                              sortDataType={this.state.sortDataType}
                            />
                          ) : this.state.selectedsegment === "UNPLEDGE" ? (
                            <NTUnpledgeMobileData
                              data={this.state.DematDetails}
                              source={this.state.source}
                              dematAcc={this.state.SelectedDematAcc}
                              selecttab={this.state.selectedsegment}
                              callback={this.sortSymbols.bind(this)}
                              requestNTPledgeUnpledge={this.requestNTPledgeUnpledge.bind(
                                this
                              )}
                              sortColumnId={this.state.sortColumnId}
                              sortType={this.state.sortType}
                              sortDataType={this.state.sortDataType}
                            />
                          ) : this.state.isFlagEnabled === true ? (
                            this.state.selectedsegment === "EXCHANGE PLEDGE" ? (
                              <NTExchangePledgeData
                                data={this.state.rePledge}
                                selecttab={this.state.selectedsegment}
                                exErr={this.state.exErr}
                                isPIB={this.state.isPIB}
                                callback={this.sortSymbols.bind(this)}
                                requestNTPledgeUnpledge={this.requestNTPledgeUnpledge.bind(
                                  this
                                )}
                                apiKey={this.state.apiKey}
                                sortColumnId={this.state.sortColumnId}
                                sortType={this.state.sortType}
                                sortDataType={this.state.sortDataType}
                              />
                            ) : (
                              <NTExchangeUnpledgeData
                                data={this.state.reUnpledge}
                                selecttab={this.state.selectedsegment}
                                isPIB={this.state.isPIB}
                                callback={this.sortSymbols.bind(this)}
                                requestNTPledgeUnpledge={this.requestNTPledgeUnpledge.bind(
                                  this
                                )}
                                sortColumnId={this.state.sortColumnId}
                                sortType={this.state.sortType}
                                sortDataType={this.state.sortDataType}
                              />
                            )
                          ) : (
                            ""
                          ),
                        ]}
                  </div>
                )}
              </div>
            </TabbarBaseWidget>
          </div>
        </div>
      </div>
    );
  }
}

export default NTPoaPledgeMobile;
