import React, { Component } from "react";
import {
  getNTPledgeUnpledgeSubmit,
  submitNTPledgeNSDLRequest,
  checkStatusForNTMarginPledgeNSDLRequest,
} from "../../actions/NTPoaPledge/NTPledgeUnpledgeRequest";
import { commonDialog, toggleDialog } from "../../actions/common";
import { connect } from "react-redux";
import OrderNumberInput from "../../controls/OrderNumberInput";
import PropTypes from "prop-types";
import { numericSort, stringSort, getItemByKey } from "../../common/utils";
import { localStorageKeys } from "../../common/constants";
import { checkenableEncrypt } from "../../../src/actions/base/api";

class NTPledgeDataMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      InDpshowDialog: false,
      symbolArr: [],
      chkbox: false,
      message: "Are you sure to submit Pledge request.",
      error: "",
      inputtext: "100",
      isquantitydisabled: false,
      maxQuantity: undefined,
      lotsize: 1,
      inuValueChange: "",
      FreeQty: "",
      ChangedQty: "",
      isCheck: true,
      data: this.props.data,
      chekbox: false,
      symbolFreeQty: [],
      selectedDematAcc: "",
      onload: true,
      modelBox: false,
      SucessMessage: false,
      apistatus: "",
      cancelPledgeTimer: "0",
      uniqueNo: "",
      pledgeTimer: "",
      reqRef: "",
      sortAsc: {
        sym_sort: false,
        qty_sort: false,
        avgprice_sort: false,
        purvalue_sort: false,
        currmktprice_sort: false,
        currmktvalue_sort: false,
        todayplperc_sort: false,
        todaypl_sort: false,
        notionalpl_sort: false,
        notionalplperc_sort: false,
        realised_sort: false,
      },
      sortColumnId: this.props.sortColumnId,
      sortType: this.props.sortType,
      sortDataType: this.props.sortDataType,
      pledgeTimer: "",
    };
    this.onSortClicked = this.onSortClicked.bind(this);
    this.SubmitClickPledge = this.SubmitClickPledge.bind(this);
    this.quantityOnChange = this.quantityOnChange.bind(this);
    this.pledgeSetTimer = this.pledgeSetTimer.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    var temp = nextProps.data,
      sortType = nextProps.sortType;

    switch (nextProps.sortDataType) {
      case "STRING":
        temp = stringSort(temp, nextProps.sortColumnId, sortType);
        break;
      case "NUMBER":
        temp = numericSort(temp, nextProps.sortColumnId, sortType);
        break;

      default:
        break;
    }
    this.setState({ data: temp });
  }

  SubmitClickPledge() {
    if (
      this.state.symbolArr.length !== 0 &&
      this.state.symbolFreeQty.length === 0
    ) {
      var symblArr = document.getElementsByClassName("pledgeCheckbox");
      var symbolDefaultQty = [];
      for (var i = 0; i < symblArr.length; i++) {
        symbolDefaultQty.push({
          isin: symblArr[i].id,
          freeQty: symblArr[i].name,
        });
      }
      this.state.symbolFreeQty = symbolDefaultQty;
    }
    this.updateSymbolArray();
    for (var i = 0; i < this.state.symbolArr.length; i++) {
      var symbolArrayIndex = this.state.symbolFreeQty.findIndex(
        (item) => item.isin === this.state.symbolArr[i].isin
      );
      if (symbolArrayIndex !== -1) {
        if (
          parseInt(this.state.symbolArr[i].qty) >
          parseInt(this.state.symbolFreeQty[symbolArrayIndex].freeQty)
        ) {
          this.setState({
            modelBox: true,
            ErrorMessage: "Quantity can not be greater than to be Pledge",
          });
          return false;
        } else if (parseInt(this.state.symbolArr[i].qty) == 0) {
          this.setState({
            modelBox: true,
            ErrorMessage: "Quantity can not be 0",
          });

          return false;
        }
      }
    }

    this.SubmitPositivePopup();
  }
  // submit api========
  SubmitPositivePopup() {
    if (
      document.getElementById("selectedDematApiStatus").innerHTML === "Y" &&
      document.getElementById("selectedDematdpName").innerHTML === "NSDL"
    ) {
      var requestUniqueNo = {
        request: {
          data: {
            requestFor: "uniqueNo",
          },
        },
      };
      this.setState({
        uniqueNo: "",
      });
      checkStatusForNTMarginPledgeNSDLRequest(
        requestUniqueNo,
        this.parseMarginPledgeNSDLSubmitResponse.bind(this),
        this.parsePledgeSubmitError.bind(this)
      );
    } else {
      var requestData = {
        request: {
          data: {
            symbolArr: this.state.symbolArr,
            demat: this.props.dematAcc,
            req_type: this.props.selecttab,
            source: "P",
          },
        },
      };
      getNTPledgeUnpledgeSubmit(
        requestData,
        this.parsePledgeSubmitResponse.bind(this),
        this.parsePledgeSubmitError.bind(this)
      );
    }
  }

  pledgeSetTimer() {
    var requestData = {
      request: {
        data: {
          requestReference: this.state.reqRef,
          requestFor: "requestStatusCheck",
        },
      },
    };
    checkStatusForNTMarginPledgeNSDLRequest(
      requestData,
      this.parseMarginPledgeNSDLSubmitResponse.bind(this),
      this.parsePledgeSubmitError.bind(this)
    );
  }

  pledgeStopFunction() {
    clearInterval(this.pledgeTimer);
  }

  parseMarginPledgeNSDLSubmitResponse(responseData) {
    var pledgeNSDLRes = responseData.response.data;
    if (pledgeNSDLRes.pledgeStatus !== "") {
      if (
        this.state.reqRef !== "" &&
        this.state.reqRef !== undefined &&
        this.state.reqRef !== null &&
        pledgeNSDLRes.pledgeStatus !== "NOT_FOUND" &&
        pledgeNSDLRes.pledgeStatus !== ""
      ) {
        var message = "";
        if (
          this.state.reqRef === pledgeNSDLRes.pledgeStatus &&
          pledgeNSDLRes.Message === "OK"
        ) {
          var res = JSON.parse(pledgeNSDLRes.pledgeSymbolArray);
          var showSymbolStatus = "";
          res.forEach((element) => {
            if (element.requestReference == pledgeNSDLRes.pledgeStatus) {
              showSymbolStatus +=
                "<p>" + element.isinName + " - " + element.msg + "</p>";
            }
          });
          message =
            "<p>Margin pledge Instructions done successfully ! </p>" +
            showSymbolStatus;
        } else if (
          this.state.reqRef === pledgeNSDLRes.pledgeStatus &&
          pledgeNSDLRes.Message === "UNAUTHORISED"
        ) {
          message = "The IP address from which request came is unauthorized !";
        } else {
          if (
            pledgeNSDLRes.pledgeSymbolArray !== undefined &&
            pledgeNSDLRes.pledgeSymbolArray !== null &&
            pledgeNSDLRes.pledgeSymbolArray !== ""
          ) {
            var res = JSON.parse(pledgeNSDLRes.pledgeSymbolArray);
            var showSymbolStatus = "";
            res.forEach((element) => {
              if (element.requestReference == pledgeNSDLRes.pledgeStatus) {
                showSymbolStatus +=
                  "<p>" + element.isinName + " - " + element.msg + "</p>";
              }
            });
            if (pledgeNSDLRes.Message === "OK") {
              message =
                "<p>Margin pledge Instructions done successfully ! </p>" +
                showSymbolStatus;
            }
            if (pledgeNSDLRes.Message === "FAILED") {
              if (showSymbolStatus !== "") {
                message =
                  "<p>Oops!! Something went wrong, please find details below </p>" +
                  showSymbolStatus;
              } else {
                message = "Something went wrong, please try again later !";
              }
            }
          } else {
            message = "Something went wrong, please try again later !";
          }
        }
        this.setState(
          {
            message: message,
            InDpshowDialog: false,
          },
          () => {
            this.setState({
              SucessMessage: true,
              ErrorMessage: message,
            });
          }
        );
        window.setTimeout(() => {
          var modal = document.getElementsByClassName("mobileErrorBox")[0];
          modal.getElementsByClassName("modal-body")[0].innerHTML = message;
        }, 200);
        this.pledgeStopFunction();
        window.sessionStorage.removeItem(localStorageKeys.PLEDGE_NSDL_REQ_REF);
      } else if (this.state.cancelPledgeTimer !== 15) {
        var count = parseInt(this.state.cancelPledgeTimer);
        this.setState({
          cancelPledgeTimer: count + 1,
        });
      } else if (this.state.cancelPledgeTimer == 15) {
        this.pledgeStopFunction();
        this.setState({
          uniqueNo: "",
          reqRef: "",
        });
        this.setState(
          {
            inProgress: false,
            error: "somthing went wrong",
          },
          () => {
            this.setState({
              SucessMessage: true,
              ErrorMessage: this.state.error,
            });
          }
        );
      }
    } else if (
      responseData.response.data.uniqueNo !== undefined &&
      responseData.response.data.pledgeStatus === ""
    ) {
      console.log("into unique number fetching shruti");
      console.log(responseData.response.data.uniqueNo);
      this.setState({
        uniqueNo: responseData.response.data.uniqueNo,
      });
      window.setTimeout(() => {
        var secDtls = [];
        console.log("shruti isin---");
        console.log(this.state.data);
        var symbol = [];
        for (var j = 0; j < this.state.symbolArr.length; j++) {
          for (var k = 0; k < this.state.data.length; k++) {
            if (this.state.symbolArr[j].isin === this.state.data[k].isin) {
              this.state.symbolArr[j]["companyName"] =
                this.state.data[k].companyName;
            }
          }
        }
        this.setState({
          cancelPledgeTimer: "0",
          reqRef: "",
        });

        for (var i = 0; i < this.state.symbolArr.length; i++) {
          console.log(this.state.symbolArr[i].isin);
          secDtls.push({
            seqNo: this.state.uniqueNo + (i + 1),
            isin: this.state.symbolArr[i].isin,
            isinName: this.state.symbolArr[i].companyName,
            quantity: parseInt(this.state.symbolArr[i].qty * 1000).toString(),
            lockInReasonCode: "",
            lockInReleaseDate: "",
          });
          symbol.push(this.state.symbolArr[i].symbol);
        }
        window.sessionStorage.removeItem(localStorageKeys.PLEDGE_NSDL_REQ_REF);
        var source1 = "P" + this.state.uniqueNo;

        window.sessionStorage.setItem(
          localStorageKeys.PLEDGE_NSDL_REQ_REF,
          checkenableEncrypt(source1)
        );
        var reqRefence = getItemByKey(localStorageKeys.PLEDGE_NSDL_REQ_REF);
        if (
          reqRefence.length >= 2 &&
          reqRefence.charAt(0) == '"' &&
          reqRefence.charAt(reqRefence.length - 1) == '"'
        ) {
          reqRefence = reqRefence.substring(1, reqRefence.length - 1);
        }
        this.setState({
          reqRef: reqRefence,
        });
        var requestData = {
          request: {
            secDtls: JSON.stringify(secDtls),
            dpId: this.props.dpId,
            clientId: window.sessionStorage.getItem("userId"),
            appName: "",
            requestType: this.props.selecttab,
            source: "P",
            noOfSecurities: secDtls.length,
            dematAcc: this.props.dematAcc,
            requestReference: this.state.reqRef,
            symbol: symbol.toString(),
            appkey: this.props.appkey,
          },
        };
        submitNTPledgeNSDLRequest(
          requestData,
          this.parsePledgeSubmitResponse.bind(this),
          this.parsePledgeSubmitError.bind(this)
        );
        this.pledgeTimer = setInterval(this.pledgeSetTimer, 2000);
        document.getElementById("submitRequest").disabled = true;
      }, 100);
    }
  }

  parsePledgeSubmitResponse(responseData) {
    this.setState(
      {
        message: responseData.response.data.MESSAGE,
        InDpshowDialog: false,
      },
      () => {
        this.setState({
          SucessMessage: true,
          ErrorMessage: this.state.message,
        });
      }
    );
  }
  parsePledgeSubmitError(error) {
    if (
      document.getElementById("selectedDematApiStatus").innerHTML !== "Y" &&
      document.getElementById("selectedDematdpName").innerHTML !== "NSDL"
    ) {
      this.setState(
        {
          inProgress: false,
          error: "somthing went wrong",
        },
        () => {
          this.setState({
            SucessMessage: true,
            ErrorMessage: this.state.error,
          });
        }
      );
    } else if (
      document.getElementById("selectedDematApiStatus").innerHTML === "Y" &&
      document.getElementById("selectedDematdpName").innerHTML === "NSDL"
    ) {
      if (this.state.cancelPledgeTimer !== 10) {
        var count = parseInt(this.state.cancelPledgeTimer);
        this.setState({
          cancelPledgeTimer: count + 1,
        });
      } else if (this.state.cancelPledgeTimer == 10) {
        this.pledgeStopFunction();
        this.setState({
          uniqueNo: "",
          reqRef: "",
        });
        this.setState(
          {
            inProgress: false,
            error: "somthing went wrong",
          },
          () => {
            this.setState({
              SucessMessage: true,
              ErrorMessage: this.state.error,
            });
          }
        );
      }
    }
  }
  quantityOnChange(pledgeqty, totalqty, isin, inputqty) {
    this.setState({
      FreeQty: pledgeqty,
      ChangedQty: inputqty,
    });
    this.updateSymbolArray();
    var symbolArrayIndex = this.state.symbolArr.findIndex(
      (item) => item.isin === isin
    );
    if (symbolArrayIndex !== -1) {
      if (parseInt(inputqty) > parseInt(pledgeqty)) {
        this.setState({
          modelBox: true,
          ErrorMessage:
            "Your total quantity is " +
            parseInt(totalqty) +
            "quantity can not be greater than " +
            parseInt(pledgeqty),
        });
        document.getElementById("submitRequest").disabled = true;
        return false;
      } else if (parseInt(inputqty) == 0) {
        this.setState({
          modelBox: true,
          ErrorMessage: "Quantity cannot be 0",
        });
        document.getElementById("submitRequest").disabled = true;
        return false;
      } else {
        this.setState(
          {
            inuValueChange: inputqty,
          },
          () => {}
        );
        document.getElementById("submitRequest").disabled = false;
      }
      if (
        this.state.symbolArr.length !== 0 &&
        this.state.symbolArr.length !== null
      ) {
        var ArrayIndex = this.state.symbolArr.findIndex(
          (item) => item.isin === isin
        );
        if (ArrayIndex !== -1) {
          this.state.symbolArr[ArrayIndex].qty = inputqty;
        }
      }
    }
  }

  updateSymbolArray() {
    if (this.state.onload == true) {
      this.state.selectedDematAcc =
        document.getElementById("selectDematId").innerText;
    }
    this.state.onload = false;
    if (
      this.state.onload == false &&
      document.getElementById("selectDematId").innerText !==
        this.state.selectedDematAcc
    ) {
      this.state.selectedDematAcc =
        document.getElementById("selectDematId").innerText;
      this.state.chekbox = false;
    }
    if (this.state.chekbox === false) {
      var symblArr = document.getElementsByClassName("pledgeCheckbox");
      var checkboxArr = [];
      var symbolDefaultQty = [];
      for (var i = 0; i < symblArr.length; i++) {
        checkboxArr.push(JSON.parse(symblArr[i].getAttribute("data-tag")));
        symbolDefaultQty.push({
          isin: symblArr[i].id,
          freeQty: symblArr[i].name,
        });
      }
      this.state.symbolFreeQty = symbolDefaultQty;
      this.state.symbolArr = checkboxArr;
    }
    this.setState({
      chekbox: true,
    });
  }

  symbolSelectionChanged(e, qty) {
    if (
      document.getElementById("submitRequest").disabled === false &&
      document.getElementById("selectDematId").innerText !==
        this.state.selectedDematAcc
    ) {
      this.state.chekbox = false;
    }
    this.updateSymbolArray();
    this.setState({
      chkbox: true,
    });
    if (e.target.checked) {
      var quantites = document.getElementsByClassName("qtyChange");
      var qtyArr = [];
      for (var i = 0; i < quantites.length; i++) {
        qtyArr.push({
          id: quantites[i].id.split("qty")[1],
          qty: quantites[i].getElementsByTagName("input")[0].value,
        });
      }
      var changeQuntyIndx = qtyArr.findIndex(
        (item) => item.id === JSON.parse(e.target.getAttribute("data-tag")).isin
      );

      var checkArrIndex = this.state.symbolArr.findIndex(
        (item) =>
          item.isin === JSON.parse(e.target.getAttribute("data-tag")).isin
      );

      if (checkArrIndex === -1) {
        this.state.symbolArr.push({
          symbol: JSON.parse(e.target.getAttribute("data-tag")).symbol,
          isin: JSON.parse(e.target.getAttribute("data-tag")).isin,
          segment: JSON.parse(e.target.getAttribute("data-tag")).segment,
          qty: qtyArr[changeQuntyIndx].qty,
        });
      }
      if (this.state.symbolArr.length !== 0) {
        document.getElementById("submitRequest").disabled = false;
        document.getElementById("submitRequest").style = "";
        document.getElementById("unselectAll").disabled = false;
      }
    } else {
      var checkArrIndex = this.state.symbolArr.findIndex(
        (item) =>
          item.isin === JSON.parse(e.target.getAttribute("data-tag")).isin
      );

      if (checkArrIndex !== -1) {
        this.state.symbolArr.splice(checkArrIndex, 1);
      }
      if (this.state.symbolArr.length === 0) {
        document.getElementById("submitRequest").disabled = true;
        document.getElementById("unselectAll").disabled = true;
        document.getElementById("submitRequest").style =
          "opacity: '0.5 !important', cursor:'no-drop'";
      }
      this.setState({
        chkbox: false,
      });
    }
  }

  handleChange(e) {}
  // shorting on symbol=====

  onSortClicked(e) {
    var sortTemp = this.state.sortAsc;
    var sortcolumn = "";
    var sortDataType = "";
    switch (e.currentTarget.id) {
      case "sym_sort":
        sortTemp.sym_sort = !sortTemp.sym_sort;
        sortDataType = "STRING";
        sortcolumn = "symbol";
        break;
      case "pled_sort":
        sortTemp.pled_sort = !sortTemp.pled_sort;
        sortDataType = "NUMBER";
        sortcolumn = "toPledgeQty";
        break;
      default:
        break;
    }
    this.props.callback(
      sortcolumn,
      sortTemp[e.currentTarget.id] ? "ASC" : "DSC",
      sortDataType
    );
  }

  // error dialog false
  onNegativeClick() {
    this.setState({
      modelBox: false,
    });
  }

  onSucessClick() {
    this.setState(
      {
        SucessMessage: false,
      },
      () => {
        this.props.requestNTPledgeUnpledge();
      }
    );
  }

  unselectAll() {
    var symblArr = document.getElementsByClassName("pledgeCheckbox");
    for (var i = 0; i < symblArr.length; i++) {
      document.getElementById(symblArr[i].id).checked = false;
    }
    this.setState(
      {
        chekbox: true,
        symbolArr: [],
      },
      () => {
        document.getElementById("submitRequest").disabled = true;
        document.getElementById("unselectAll").disabled = true;
      }
    );
    if (
      this.state.onload == false &&
      document.getElementById("selectDematId").innerText !==
        this.state.selectedDematAcc
    ) {
      this.state.chekbox = false;
      document.getElementById("submitRequest").disabled = false;
      document.getElementById("unselectAll").disabled = false;
      this.updateSymbolArray();
    }
  }

  render() {
    return (
      <div className="fillheight pledgeDataHeight">
        <div className="row">
          <button
            type="button"
            id="unselectAll"
            class="btn-footer order-place active-buy unselectMobile"
            onClick={this.unselectAll.bind(this)}
          >
            UNSELECT ALL
          </button>
        </div>
        <div
          className="row contentpanel dpholdings-header"
          style={{ display: "flex", color: "#000", paddingTop: "35px" }}
        >
          <div className="col-4">Select</div>
          <div
            className="col-4"
            id="sym_sort"
            onClick={(e) => this.onSortClicked(e)}
          >
            <img
              src="assets/svg/sort-mobile.png"
              className="fa-sort"
              alt="sort"
            ></img>
            Symbol
          </div>
          <div
            className="col-4 text-right"
            id="qty_sort"
            onClick={(e) => this.onSortClicked(e)}
          >
            <img
              src="assets/svg/sort-mobile.png"
              className="fa-sort"
              alt="sort"
            ></img>
            QTY
          </div>
        </div>
        <div className="dp-scr">
          {this.state.data.map((data, index) => (
            <div style={{ background: index < 3 && "#fff" }}>
              <div
                className="row contentpanel"
                style={{ display: "flex", paddingTop: 10, paddingBottom: 10 }}
              >
                <div className="col-4">
                  <input
                    type="checkbox"
                    class="pledgeCheckbox"
                    defaultChecked={this.state.isCheck}
                    data-tag={JSON.stringify({
                      symbol: data.symbol,
                      isin: data.isin,
                      segment: data.segment,
                      qty: data.toPledgeQty,
                    })}
                    id={data.isin}
                    onChange={this.symbolSelectionChanged.bind(this)}
                    name={data.toPledgeQty}
                  />
                </div>
                <div className="col-4" style={{ color: "#000" }}>
                  {data.symbol}
                </div>
                <div className="col-4 text-right pledgeinput mobile-input-qty">
                  <span class="qtyChange" id={"qty" + data.isin}>
                    <OrderNumberInput
                      onChange={this.quantityOnChange.bind(
                        this,
                        data.freeQty,
                        data.freeQty,
                        data.isin
                      )}
                      defaultValue={data.toPledgeQty}
                      isnumber={true}
                      tabIndex="1"
                      isDisabled={this.state.isquantitydisabled}
                      incrementvalue={this.state.lotsize}
                      minValue={this.state.lotsize}
                      maxValue={this.state.maxQuantity}
                    />
                  </span>
                </div>
              </div>
              <div className="hdivider" style={{ marginBottom: 0 }} />
            </div>
          ))}

          <div className="row">
            <div className="col-12 text-center" style={{ margin: "15px 0px" }}>
              <button
                type="button"
                id="submitRequest"
                className="btn btnColor btn-primary form-control"
                tabIndex="8"
                onClick={this.SubmitClickPledge.bind(this)}
              >
                Submit Pledge Request
              </button>
            </div>
          </div>
        </div>
        {this.state.modelBox === true ? (
          <div
            class="modal fade dialog-show in"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              class="modal-dialog appdialogmodal mobileErrorBox"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Message
                  </h5>
                </div>
                <div class="modal-body">
                  <p style={{ color: "#000" }}>{this.state.ErrorMessage}</p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="text-center btn btn-success"
                    data-dismiss="modal"
                    onClick={this.onNegativeClick.bind(this)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.SucessMessage === true ? (
          <div
            class="modal fade dialog-show in"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              class="modal-dialog appdialogmodal mobileErrorBox"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Message
                  </h5>
                </div>
                <div class="modal-body">
                  <p style={{ color: "#000" }}>{this.state.ErrorMessage}</p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="text-center btn btn-success"
                    data-dismiss="modal"
                    onClick={this.onSucessClick.bind(this)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

NTPledgeDataMobile.protoTypes = {
  callback: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownprops) => {
  return {
    commonDialog: (payload) => commonDialog(dispatch, payload),
    toggleDialog: (payload) => toggleDialog(payload, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NTPledgeDataMobile);
