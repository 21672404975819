import { Environment, getBaseUrl, getwsInteractiveUri, getwsInteractiveUriNetMagic, getwsBroadcastUri, getwsBroadcastUriNetMagic, getwsBroadcastUriTR,getwsBroadcastUriTRNetMagic,getIsEncryption,getInactivityTimeout, getBaseUrlShweta } from "./serviceurls";

//===================================================
// Environment

export const ReleaseEnvironment = process.env.REACT_APP_ENV;
export const appVersion = "3.0.0";
export const appReleaseVersion = process.env.REACT_APP_RELEASE_VERSION;

//===================================================
// API Urls 

export const BaseUrl = getBaseUrl(ReleaseEnvironment);
// export const BaseUrlShweta = getBaseUrlShweta(ReleaseEnvironment);
export const wsInteractiveUri = getwsInteractiveUri(ReleaseEnvironment);
export const wsInteractiveUriNetMagic = getwsInteractiveUriNetMagic(ReleaseEnvironment);
export const wsBroadcastUri = getwsBroadcastUri(ReleaseEnvironment);
export const wsBroadcastUriNetMagic = getwsBroadcastUriNetMagic(ReleaseEnvironment);
export const wsBroadcastUriTR = getwsBroadcastUriTR(ReleaseEnvironment);
export const wsBroadcastUriTRNetMagic = getwsBroadcastUriTRNetMagic(ReleaseEnvironment);

export const enableEncryption = getIsEncryption(ReleaseEnvironment);
export const encryptionKey = "indiabullsots201";
export const inactivityTimeOut = getInactivityTimeout(ReleaseEnvironment);

//===================================================
// Remove logs and right click

if (process.env.REACT_APP_RELEASE_ENABLE_CONTEXTMENU !== 'true') {
    document.addEventListener('contextmenu', event => event.preventDefault());
    console.log = function () { };
    console.warn = function () { };
    console.error = function () { };
    console.debug = function () { };
}

//================================================== 
// Constants
export const UrlExtensions = {
    LOGOUT: "/Account/Logout",
    PAN_LOGIN: "/Account/Login",
    LOGIN_OTP: "/Account/Login/SendOTP",
    LOGIN_VALIDATE_OTP:"/Account/Login/ValidateOTP",
    RESET_PASSWORD: "/Account/ResetPassword",
    PAN_VERIFICATION: "/Account/VerifyPAN",
    GENERATE_OTP: "/Account/GenerateOTP",
    VERIFY_OTP: "/Account/VerifyOTP",
    UNLOCK_ACCOUNT: "/Account/UnlockAccount",
    GET_CLIENT_ID: "/Account/GetClientId",
    RESET_TPIN: "/Account/ResetTPIN",
    CHANGE_PASSWORD: "/Account/ChangePassword",
    CHANGE_TPIN: "/Account/ChangeTPIN",
    MTF_ACTIVATION: "/Account/MTFActivation",
    DORMANT_CLIENTID: "/Dormant/DormantCheck",
    DORMANT_REGENERATE_OTP: "/Dormant/RegenerateOTP",
    DORMANT_VERIFY_OTP: "/Dormant/VerifyOTP",

    SYMBOL_SEARCH: "/Trade/SymbolSearch",
    TOP_GAINERS: "/Market/TopGainers",
    GET_CONFIG: "/App/Config",
    TOP_LOSERS: "/Market/TopLosers",
    OPEN_ORDERS: "/Trade/OpenOrders",
    PENDING_ORDERS: "/Market/PendingOrders",
    INVESTMENT_OVERVIEW: "/Account/HoldingsSummary",
    HOT_NEWS: "/News/GetLiveNews",
    COMAPANY_NEWS: "/News/StockTrendingNews",
    CURRENCY_NEWS: "/News/CurrencyNews",
    COMMODITY_NEWS: "/News/CommodityNews",
    SECTOR_NEWS: "/News/SectorWiseNews",
    DISCUSSION_NEWS: "/News/StockDiscCloud",
    LIVE_NEWS_DETAIL: "/News/GetLiveNewsDetails",
    DERIVATIVE_NEWS: "/News/DerivativeSymbolNews",
    IDEAS: "/Ideas/GetResearchCalls",
    WORLD_MARKET: "/Market/WorldIndices",
    INDEX_QUOTE: "/Market/GetIndexQuote",

    WATCHLIST_GROUPS: "/Watchlist/ListGroups",
    ADD_WATCHLIST: "/Watchlist/AddGroup",
    RENAME_WATCHLIST: "/Watchlist/UpdateGroup",
    DELETE_WATCHLIST: "/Watchlist/DeleteGroup",
    ADD_SCRIP_TO_WATCHLIST: "/Watchlist/AddSymbol",
    DELETE_SCRIP_FROM_WATCHLIST: "/Watchlist/DeleteSymbol",
    WATCHLIST_SYMBOL_LIST: "/Watchlist/ListSymbols",
    WATCHLIST_UPDATE_FAVOURITE: "/Watchlist/UpdateFavourite",

    ADD_ORDER: "/Trade/AddOrder",
    ADD_SPREAD_ORDER: "/Trade/AddSpreadOrder",
    MODIFY_ORDER: "/Trade/ModifyOrder",
    CANCEL_ORDER: "/Trade/CancelOrder",
    EXIT_COVER_ORDER: "/Trade/ExitCoverOrder",
    EXIT_BRACKET_ORDER: "/Trade/ExitBracketOrder",
    MARGIN_REQUIRED : "/Trade/CheckMargin",
    GET_CHARGES : "/Trade/Charges",

    REPORT_DIGITAL_CONTRACT_NOTE: "/Reports/DigitalContract",
    DOWNLOAD_CONTRACT_PDF: "/Reports/downloadPDF",
    REPORT_MARKET_FNO: "/Market/FNOReports",
    REPORT_MARKET_CURRENCY: "/Market/CurrReports",
    REPORT_MARKET_EQUITY: "/Market/EquityReports",
    REPORT_CAPITAL_GAIN: "/Report/CapitalGLReport",
    REPORT_GAIN_SUMMARY: "/Report/GainSummary",
    BACK_OFFICE: '/Reports/SSOReport',
    CLIENT_LEDGER: '/Account/LedgerBalance',
    MY_ORDERS: "/Trade/MyOrders",
    MY_TRADES: "/Trade/MyTrades",
    ORDER_LOG: "/Order/OrderLog",
    MARGIN_DETAILS: "/Account/MarginDetails",
    MARGIN_UTILIZATION_PERCENT: "/Account/MarginUtilizationPercent",
    NET_POSITIONS: "/Account/NetPositions",
    NET_POSITIONS_TRANSFER_LOG: "/Trade/TransferLogs",
    NET_POSITIONS_TRANSFER: "/Account/TransferPosition",

    GET_QUOTE: "/Market/GetQuote",
    CONTRACT_DETAILS: '/Market/GetContractDetails',
    SCRIP_OPTION_CHAIN: "/Market/GetOptionChain",
    HODLING_POSITION_SUMMARY: "/Account/HoldingsBySymbol",
    HOLDINGS_SUMMARY_BY_SYMBOL: "/Account/HoldingsSummaryBySymbol",
    GET_LTPRANGE_COVER: "/Trade/GetLtpCoverTriggerPriceRange",

    SCRIP_TECHNICALS: '/Market/Technicals',

    FINANCIALS_RESULTS: '/Market/GetFinancialResultsQuarterly',
    FINANCIALS_RATIOS: '/Market/GetKeyFinancialRatios',
    FINANCIALS_SHARE_HOLDING: '/Market/GetShareHoldingPattern',
    FINANCIALS_BALANCESHEET: '/Market/GetBalanceSheet',
    FINANCIALS_PROFITLOSS: '/Market/GetProfitAndLoss',
    FINANCIALS_SCOREBOARD: '/Market/GetScoreBoard',
    FINANCIALS_CASHFLOW: '/Market/GetCashFlow',

    PEERS_RATIOS: '/Market/GetPeerCompFinancialRatios',
    PEERS_BALANCESHEET: '/Market/GetPeerCompBalanceSheet',
    PEERS_PROFITLOSS: '/Market/GetPeerCompProfitAndLoss',

    GET_EXCHANGEMESSAGES: '/Info/GetExchangeMessages',
    GET_IMPORTANTLINKS: '/Info/GetImportantLinks',
    GET_BHAVCOPY: '/Info/GetBhavCopy',
    GET_LOGINALERTS: '/Info/GetLoginAlerts',
    GET_EXCHANGETIMINGS: '/Info/GetExchangeTimings',
    GET_PRICECHART: '/Chart/PriceChart',
    SET_PREFERENCES: '/Chart/ChartPreferences',
    GET_PREFERENCES: '/Chart/GetChartPreferences',
    GET_NOTIFICATIONS: '/Alerts/GetOrderAndTradeAlerts',
    GET_CORPORATE_ACTIONS: '/Info/CorpActions',
    GET_MARKETSTATUS: '/Info/MarketStatus',
    LOG_RISK: '/RiskDisclosure',

    GET_USER_PROFILE: '/UserProfile/GetUserProfile',
    GET_COMMODITY_USER_PROFILE: '/UserProfile/GetUserProfileCommodity',
    GET_CASH_DPHOLDING: "/DPHoldings/Cash",
    GET_MARGIN_DPHOLDING: "/DPHoldings/Margin",
    GET_NOMINEE_DETAILS: "/Profile/Nominees",

    GET_FUND_TRANSFER_LOG: '/FundTransfer/GetFundTransferLogs',
    GET_PENDING_PAYOUTS: '/TR/PendingPayouts',
    GET_BANK_DETAILS: '/FundTransfer/GetBankDetails',
    GET_WITHDRAW_DETAILS: '/FundTransfer/GetBankDetails',
    GET_PAY_IN_OPTIONS: '/FundTransfer/GetPayInOptions',
    PLACE_PAY_IN_REQUEST: '/FundTransfer/PlacePayInRequest',
    RAZOR_PAY_IN_REQUEST :'/Gateway/RazorPay',
    PLACE_PAY_OUT: '/FundTransfer/PlacePayOutRequest',
    CANCEL_PAY_OUT: '/FundTransfer/CancelPayoutRequest',
    ALLOCATE_FUNDS_REPORT_URL: '/Allocate/Funds/Report',
    CHART_BASE_REQUEST: '/Chart/PriceChart',
    GET_IPO: '/IPO/GetIPO',
    GET_IPO_DATA: '/IPO/GetIPOData',
    VALIDATE_DEMAT_ACC:'/IPO/ValidateDematAcc',
    ADD_IPO:'/IPO/AddIPO',
    ORDER_BOOK:'/IPO/OrderBook',
    DP_DETAILS:'/IPO/DPDetails',

    GET_WEEK_HIGH: '/MarketStats/52WkHigh',
    GET_WEEK_LOW: '/MarketStats/52WkLow',
    GET_SUMMARY_GAINLOS: '/MarketStats/Summary',
    GET_SUMMARY_GAPUP_HIGH: '/MarketStats/GapUpHigh',
    GET_SUMMARY_GAPUP_DOWN: '/MarketStats/GapUpDown',
    GET_BUZZING_STOCKS: '/MarketStats/BuzzingStockNews',

    GET_CORPACTCALENDER: '/MarketStats/GetCalCorpAc',
    GET_CALENDERRESULTS: '/MarketStats/GetCalCorpResults',
    GET_HEATMAP: '/MarketStats/HeatMap',
    GET_SECTORSLIST: '/MarketStats/SectorsList',
    GET_ADVANCEDECLINE_BY_VOL: '/MarketStats/AdvDeclByVol',
    GET_ADVANCEDECLINE_BY_PRICE: '/MarketStats/AdvDeclByPrice',
    GET_FIIDIIACTIVITY: "/MarketStats/FIIDIIMF",
    GET_INTRADAYDEALS: '/MarketStats/BulkDeals',
    GET_MOSTACTIVE: '/MarketStats/MostActive',
    GET_INDICES: '/MarketStats/GetIndices',
    GET_INDEX_SYMBOL_DATA: '/MarketStats/IdxSymbols',
    GET_GRADES: '/MarketStats/UpgradeDowngrade',

    GET_SCREENERS: '/MarketStats/ScreenerWatch',

    GET_HECKYL_SECTORS: '/MarketStats/HeckylSectors',
    GET_FUTURES_MARKET_INDICATOR: '/MarketStats/FuturesMarketIndicator',
    GET_OPTIONS_MARKET_INDICATOR: '/MarketStats/OptionsMarketIndicator',

    GET_FNO_MOSTACTIVE_FUTURES: '/MarketStats/FuturesMarketIndicator',
    GET_FNO_MOSTACTIVE_OPTIONS: '/MarketStats/OptionsMarketIndicator',
    GET_FNO_IVGAINERS: '/MarketStats/IVGainerLoser',
    GET_FNO_PCOIR: '/MarketStats/UnusualPCOIR',
    GET_FNO_PCVR: '/MarketStats/UnusualPCVR',
    GET_FNO_ROLLOVER: '/MarketStats/RollOverData',
    GET_FNO_PULSE: '/MarketStats/DerivativeMarketNews',
    GET_FNO_UNUSUAL_BREAKOUT: '/MarketStats/PriceAction',
    GET_FNO_SECTORDATA: '/MarketStats/SectorData',
    GET_EQUITY_SECTORDATA: '/MarketStats/GetSectorWatch',
    GET_FNO_CPOIR: '/MarketStats/UnusualCPOIR',
    GET_FNO_CPVR: '/MarketStats/UnusualCPVR',
    GET_FNO_PGP: '/MarketStats/PositiveGainPrem',
    GET_FNO_NGP: '/MarketStats/NegativeGainPrem',
    GET_FNO_UCV: '/MarketStats/UnusualCallVolume',
    GET_FNO_PCV: '/MarketStats/UnusualPutVolume',

    GET_CURRENCY_MARKET_STATS: '/MarketStats/CurrencyStats',
    GET_CURRENCY_EXP_STRIKE: '/MarketStats/CurrExpStrike',
    GET_CURRENCY_NEWS: '/News/CurrencyNews',

    GET_COMMODITY_NEWS: '/News/CommodityNews',
    GET_COMMODITY_FUTURES: '/MarketStats/TopCommodityFutures',
    GET_COMMODITY_OPTIONS: '/MarketStats/TopCommodityOptions',
    GET_COMMODITY_SPOT: '/MarketStats/CommoditySpots',

    OPTION_CALCULATOR: '/Calculators/CalculateOptions',
    SPAN_CALC_INSTRUMENT: '/Span/GetInstruments',
    SPAN_CALC_SYMBOLS: '/Span/GetSymbols',
    SPAN_CALC_CONTRACTS: '/Span/GetContracts',
    SPAN_CALC_EXPIRIES: '/Span/GetExpiries',
    SPAN_CALCULATION: '/Span/CalculateSpan',
    SPAN_GET_POSITION: '/Span/CalculateSpanFromPositions',

    VALIDATE_SESSION: '/Account/ValidateSession',
    SSOService: '/Business/SSOService',
    PORTFOLIO: '/Business/Porfolio',
    TRADEHISTORY:'/Business/TradeHistory',
    MYTRADINGPLAN: '/Business/MyTradingPlan',
    TRADINGMASTERPLAN: '/Business/MasterSubscriptionPlan',
    SUBSCRIPTIONREQUEST: '/Business/SubscriptionReq',
    LIMIT_AGAINST_HOLDING:'/Pledging/LimitAgainstHoldingReq',
    NEW_PORTFOLIO: '/Business/PorfolioV2',

    DECRYPT_PARAM_NT: '/NT/nest-service',

    DELETE_BASKET:'/Basket/DeleteBasket',
    CREATE_BASKET:'/Basket/AddBasket',
    GET_BASKETLIST:'/Basket/GetBasketList',
    ADD_BASKET_SYMBOL:'/Basket/AddBasketSymbol',
    RENAME_BASKTE:'/Basket/RenameBasket',
    GET_BASKET_SYMBOL:'/Basket/GetBasketSymbolList',
    BASKET_EXCUTION:'/Basket/ExecuteBasket',
    BASKET_EXECUTION_LOG:'/Basket/ExecutionLog',
    DELETE_BASKET_SYMBOL:'/Basket/DeleteBasketSymbols',
    MODIFY_BASKET_ORDER:'/Basket/ModifyBasketSymbol',

    DPID_DATA:'/EdisApiInnovationServlet',
    CDSL_URL:'/CDSLEdisAPIInnovationServlet',
    NSDL_URL : '/NSDLApiInnovationServlet',
    MARKETOPEN :'/MarketStatusService',
    
    CLIENT_PORTFOLIO:'/Client/PortFolio',
    PLEDGEUNPLEDGEDATA: '/Pledge/Detail',
    PLEDGEUNPLEDGEDATANEW: '/Pledge/DetailV2',
    NTPLEDGEUNPLEDGEDATA: '/NT/Pledge/Detail',
    PLEDGESUBMITREQUEST:'/Pledge/Request',
    NTPLEDGESUBMITREQUEST: '/NT/Pledge/Request',
    PLEDGE_NSDL_URL:'/Pledge/Online/NSDL',
    PLEDGE_CDSL_URL:'/Pledge/Online/CDSL',
    PLEDGE_NSDL_REQUEST_FOR : '/pledge/marginPledgeNSDLRequestFor',
    EXCHANGE_PLEDGE_UNPLEDGE: '/Exchange/PledgeUnpledge',
    NT_EXCHANGE_PLEDGE_UNPLEDGE: '/NT/Exchange/PledgeUnpledge',
    CDSLFORGETTPIN: '/Client/ForgotTPIN',
    BACKOFFICE_PREVIOUS_FY: '/Reports/ReportWebService',
    EMAILID_PROFILE:'/Client/VerifyContactEmail',
    TR_STREAM_FLAG:'/TRstreamStatus',    	
    STREAM_LOGGING:'/Stream/Logging',
    EDIS_REQ: '/CDSLEdisRevokeReq',
    EDIS_CDSL_REVOKE:'/CDSLEdisCancellationServlet',
    ORDER_DETAILS: '/Order/Token/Detail',

    CDSL_EVOTING_URL:'/CDSLEvotingRequest',
    NSDL_EVOTING_URL:'/NSDLEvotingRequest',

    GET_DEMAT_DETAILS: '/initPay/clientDematDetails',
    ATOM_PAYMENT_REQUEST: '/initPay/AtomPay',
    GET_CAPTCHA: '/generateCaptcha'
};

//===================================================
// Store keys
export const StoreKeys = {
    CHECK_SESSION: "CHECK_SESSION",
    TO_LOGOUT: "TO_LOGOUT",
    LOGIN_STATUS: "LOGIN_STATUS",
    ACCOUNT_DETAILS: "ACCOUNT_DETAILS",
    CUG_FLAG: "N",
    DASH_ACTIVE_WIDGET: "DASH_ACTIVE_WIDGET",
    FOOTER_ACTIVE_WIDGET: "FOOTER_ACTIVE_WIDGET",
    MARKETSTATS_ACTIVE_WIDGET: "MARKETSTATS_ACTIVE_WIDGET",
    WATCHLIST_ACTIVE_WIDGET: "WATCHLIST_ACTIVE_WIDGET",
    WATCHLIST_ACTIVE_WIDGET_STATE: "WATCHLIST_ACTIVE_WIDGET_STATE",
    WATCHLIST_PRE_SELECTED: "WATCHLIST_PRE_SELECTED",
    WATCHLIST_GROUPS: "WATCHLIST_GROUPS",
    CHARTS_ACTIVE_WIDGET: "CHARTS_ACTIVE_WIDGET",
    CHARTS_ACTIVE_WIDGET_STATE: "CHARTS_ACTIVE_WIDGET_STATE",
    NEWS_ACTIVE_WIDGET: "NEWS_ACTIVE_WIDGET",
    CONFIG_STATUS: "CONFIG_STATUS",
    SHOW_DIALOG: "SHOW_DIALOG",
    SHOW_MARKET_DEPTH: "SHOW_MARKET_DEPTH",
    SHOW_ORDER_DIALOG: "SHOW_ORDER_DIALOG",
    SHOW_ORDER_DIALOG_DATA: "SHOW_ORDER_DIALOG_DATA",
    SHOW_BID_DIALOG: "SHOW_BID_DIALOG",
    ON_ORDER_SUCCESS: "ON_ORDER_SUCCESS",
    SHOW_CANCEL_ORDER_DIALOG: "SHOW_CANCEL_ORDER_DIALOG",
    SHOW_CANCEL_ORDER_DIALOG_DATA: "SHOW_CANCEL_ORDER_DIALOG_DATA",
    SHOW_CANCEL_ORDER_DIALOG_CALLBACK: "SHOW_CANCEL_ORDER_DIALOG_CALLBACK",
    SCRIP_ACTIVE_WIDGET: "SCRIP_ACTIVE_WIDGET",
    SCRIP_INFO: "SCRIP_INFO",
    DEPTH_DETAILS: 'DEPTH_DETAILS',
    ADD_SYMBOL_WATCHLIST: "ADD_SYMBOL_WATCHLIST",
    WATCHLIST_SELECTED_1: "WATCHLIST_SELECTED_1",
    WATCHLIST_SELECTED_2: "WATCHLIST_SELECTED_2",
    SHOW_CHART_DIALOG: "SHOW_CHART_DIALOG",
    CHART_SYMBOL: "CHART_SYMBOL",
    CHART_SELECT_LIST: "CHART_SELECT",
    SCRIP_OPTION_CHAIN: "SCRIP_OPTION_CHAIN",
    SHOW_LOGIN_WARNING: "SHOW_LOGIN_WARNING",
    SHOW_MTF_DEFNITION: "SHOW_MTF_DEFNITION",
    SHOW_MTF_ALERT: "SHOW_MTF_ALERT",
    SHOW_RISK_DISCLOSURE: "SHOW_RISK_DISCLOSURE"
};

//change this keys will affect in {PositionDataHolder} for transfer
export const productType = { cash: "CASH", margin: "MARGIN", intraday: "INTRADAY", carryForward: "CARRY FORWARD", SHORTSELL: "SHORT SELL", BO: "BRACKET ORDER", CO: "COVER ORDER",SP:"Spread",L:"Normal" };
export const positionConvertionTypesEquity = { carryForward: [{ name: 'INTRADAY' }], intraday: [{ name: 'CASH' }], SHORTSELL: [{ name: 'CASH' }], margin: [{ name: 'CASH' }], cash: [{ name: 'INTRADAY' }] }
export const commonDropDownType = {
    DEFAULT: "DEFAULT",
    ICON: "ICON",
    CUSTOM: "CUSTOM"
}

export const SubMenuDirections = {
    DOWN: "DOWN",
    RIGHT: "RIGHT"
}

//local storage keys
export const localStorageKeys = {
    CONFIG_KEY: !enableEncryption ? "CONFIG_KEY" : "Y6b+vo0pkq1ip0V8SpvVqw==",
    LOGIN_STATUS: !enableEncryption ? "LOGIN_STATUS" : "qgbAr9ZkbY0huF1jEgoyqg==",
    WATCHLIST_GROUPS: !enableEncryption ? "WATCHLIST_GROUPS" : "vglkCJlVFfePI3UZwkdlWmGDysE+5j/BGqdfhFjyZ8k=",
    ACCOUNT_ID: !enableEncryption ? "ACCOUNT_ID" : "RcvoUya4Sr1IK2VAMzsL7A==",
    ACCOUNT_DETAILS: !enableEncryption ? "ACCOUNT_DETAILS" : "qfpBBLubMgCkRy80Tubbrw==",
    USER_EDIS_DETAILS: !enableEncryption ? "USER_EDIS_DETAILS":"DO/wQ/QYCyfnCGALQs/pADgG+e125RBzcZe6thyPaQs=",
    SCRIP_INFO: !enableEncryption ? 'SCRIP_INFO' : 'Mvs6R9o4qd884izf7gtPag==',
    ACTIVE_THEME: !enableEncryption ? 'ACTIVE_THEME' : 'NiNRPuHWei2aRH7dpDkz2A==',
    LIMITAGAINSTHOLDINGFLAG: !enableEncryption ? 'LIMITAGAINSTHOLDINGFLAG' :'18yyZG0ayqeGXZbjlhn3N3HSdJtXojJJEA6kjpSnrWQ=',   
    MergeEqCom:!enableEncryption?'MergeEqCom':'ifjD1N3sBNgdxc0pek8Tjw==',
    CLIENT_HOLDINGS : !enableEncryption ? 'CLIENT_HOLDINGS' :'zrrvWFTK7uUii/VytZNeAA==',
    SymbolOBJ : !enableEncryption ? 'SymbolOBJ' :'JIK155oLVKoLURRf+eT4uQ==',
    EDIS_RESPONSE : !enableEncryption ? 'EDIS_RESPONSE' : 'UU9bUGe/7CbVJxAZCVw2OA==',
    PLEDGE_NSDL_REQ_REF: !enableEncryption ? 'PLEDGE_NSDL_REQ_REF': 'tSXjtm70rN5i57PTxAzsqQxEDyO7jH8G4aKWs2OeEng=',
    USERSESSION_ID: !enableEncryption ? 'USERSESSION_ID' : 'GrD1GloRfKzV/8wOwvuZMw==',    
    TR_STREAM_FLAG: !enableEncryption ? 'TR_STREAM_FLAG' : '531ln9J15vzIUKPUUiooow==',
    EDIS_REVOC_RESPONSE: !enableEncryption ? 'EDIS_REVOC_RESPONSE' : 'v3NXY1OHUOapSIexvjmAh703tvcKPBKTXsFPwi47G60=',			
    PIB_APP_KEY:!enableEncryption ? 'PIB_APP_KEY' : 'UGfJp7PvBDIF5Of1XI7/MA=='
}

export const symbolSearchButtonTabs = ["FUTURE", "OPTION", "SPREAD"];
export const BasketsymbolSearchButtonTabs = ["FUTURE", "OPTION"];

export const symbolSearchTabs = ["EQUITY", "FnO", "INDEX", "CURRENCY", "COMMODITY"];

export const symbolSearchType = { header: "header", watchlist: "watchlist", orderentry: "orderentry", chart: "chartSearch" };

export const assetClasses = {
    equity: "Equity",
    fno: "FNO",
    spread: "Spread",
    currency: "Currency",
    commodity: "Commodity",
    index: "Index"
};
export const tabMap = {
    'STK': assetClasses.equity,
    'FUTSTK': assetClasses.fno,
    'FUTIDX': assetClasses.fno,
    'OPTSTK': assetClasses.fno,
    'OPTIDX': assetClasses.fno,
    'FUTSTKSPR' :assetClasses.spread,
    'FUTIDXSPR' :assetClasses.spread,
    'FUTCUR': assetClasses.currency,
    'OPTCUR': assetClasses.currency,
    'OPTFUT': assetClasses.commodity,
    'FUTCOM': assetClasses.commodity,
    'COM': assetClasses.commodity,
}
export const ideasTabs = ["Equity", "FNO", "Currency", "Commodity"];
export const ideasSortOptions = ["A-Z", "Z-A", "Buy", "Sell"];
export const ideasFilterOptions = ["All Calls", "Intraday", "BTST", "Momentum"];
export const indexTabs = { nse: "NSE", bse: "BSE", ncdex: "NCDEX", mcx: "MCX" }

export const watchlistFilterOptions = [{ name: "None" }, { name: "Most Active" }, { name: "Vol Gainers" }, { name: "OI Gainers" }, { name: "OI Loosers" }, { name: "Price Gainers" }, { name: "Price Loosers" }];
export const watchlistDownloadOptions = [{ name: "Download Excel" }, { name: "Download CSV" }];
export const watchlistTabs = ["Pre-defined", "Custom"];


export const instrumentType = {
    STOCK: "STK", INDEX: "IDX", COMMODITY: "COM", CURRENCY: "UNDCUR",
    INDEX_OPTIONS: "OPTIDX", STOCK_OPTIONS: "OPTSTK", STOCK_FUTURE: "FUTSTK",
    INDEX_FUTURE: "FUTIDX", COMMODITY_FUTURE: "FUTCOM", COMMODITY_OPTIONS: "OPTFUT",
    CURRENCY_FUTURE: "FUTCUR", CURRENCY_OPTIONS: "OPTCUR", INDEX_FUTURE_SPREAD: "FUTIDXSPR",
    STOCK_FUTURE_SPREAD: "FUTSTKSPR", CURRENCY_FUTURE_SPREAD: "FUTCURSPR"
}

export const orderEntryTypes = {
    NEW: 'NEW',
    MODIFY: 'MODIFY',
    SQUAREOFF: 'SQUAREOFF'
};
export const loginType = [{ key: 'Login Without OTP',value:'Login Without OTP'},{ key: 'Login With OTP',value:'Login With OTP'}];
export const marketAndHoldingsTab = { MARKET_DEPTH: "MARKET_DEPTH", HOLDINGS_POSITIONS: "HOLDINGS_POSITIONS" };
export const advanceOrderTypes = [{ key: 'Regular', value: 'regular' }, { key: 'Cover Order', value: 'coverorder' }, { key: 'Bracket Order', value: 'bracketorder' }]
export const orderTypes = [{ key: 'Limit', value: 'limit' }, { key: 'Market', value: 'market' }];
export const validityTypes = [{ key: 'Day', value: 'day' }, { key: 'IOC', value: 'ioc' }]; //, { key: 'VTD', value: 'gtd' }];
export const basketOrderType =[{ key: 'Regular', value: 'regular' }]

export const categoryTypes = [{ key: 'TECHNICALS', value: 'technical' }, { key: 'FUNDAMENTALS', value: 'fundamental' }];
//export const productTypes_Equity = [{ key: "Intraday", value: "intraday" }, { key: "Delivery", value: "delivery" }];
export const productTypes_Equity = [{ key: "Intraday", value: "intraday" }, { key: "Normal Delivery", value: "cash" }, { key: "Margin Trading", value: "margin" }];
export const productTypes_FUT = [{ key: "Carry Forward", value: "carryforward" }, { key: "Intraday", value: "intraday" }];
export const productTypes_OPT = [{ key: "Carry Forward", value: "carryforward" }];

export const productSubTypes_EQUITY = [{ key: "Margin", value: "margin" }, { key: "Normal", value: "cash" }];

export const orderDropdownOptions = [{ name: 'ALL', value: 'all' }, { name: 'REJECTED', value: 'rejected' }, { name: 'CANCELLED', value: 'cancelled' }, { name: 'EXECUTED', value: 'executed' }, { name: 'OUTSTANDING', value: 'outstanding' }, { name: 'REQUEST SENT', value: 'sent' }, { name: 'OFFLINE', value: 'offline' }];
//export const advanceOrderTypes = ["COVER ORDER", "BRACKET ORDER"];
export const AddFundsTypes = [{ key: 'Equity', value: 'equity' }];//, { key: 'Commodity', value: 'commodity' }]; //, { key: 'Mutual Funds', value: 'mutualfunds' }];
export const WithdrawTypes = [{ key: 'End of the Day', value: 'eod' }, { key: 'Instant', value: 'instant' }];
export const PayInTypes = [{ key: 'Net Banking', value: 'netbanking' }, { key: 'UPI', value: 'upi' }];

export const heatmapSectors = [{ name: 'Nifty 50', value: 'nifty' }, { name: 'Agriculture', value: 'agri' }];
export const heatmapSorts = [{ name: '% Change', value: 'chng_per' }, { name: 'Alphabetical', value: 'alpha' }];

export const summaryEquityWidgets = {
    topgainers: "TOP 5 PRICE GAINERS",
    toplosers: "TOP 5 PRICE LOSERS",
    topmostactive: "TOP 5 MOST ACTIVE",
    topvolumegainers: "TOP 5 VOLUME GAINERS",
    top52high: "TOP 5 STOCKS AT 52 WEEK HIGH",
    top52low: "TOP 5 STOCKS AT 52 WEEK LOW",
    topgapup: "TOP 5 GAP UP",
    topgapdown: "TOP 5 GAP DOWN",
};

export const summaryFutureWidgets = {
    toppricegainers: "TOP 5 PRICE GAINERS",
    toppricelosers: "TOP 5 PRICE LOSERS",
    topvolumegainers: "TOP 5 VOLUME GAINERS",
    topactivefutures: "TOP 5 ACTIVE FUTURES",
    topoigainers: "TOP 5 OI GAINERS",
    topoilosers: "TOP 5 OI LOSERS",
    toppremium: "TOP 5 PREMIUM",
    topdiscount: "TOP 5 DISCOUNT",
};

export const summaryOptionsWidgets = {
    topmostactivecalls: "TOP 5 MOST ACTIVE (CALLS)",
    topvolumegainerscalls: "TOP 5 VOL GAINERS (CALLS)",
    topmostactiveputs: "TOP 5 MOST ACTIVE (PUTS)",
    topvolumegainersputs: "TOP 5 VOLUME GAINERS (PUTS)",
    topoigainerscalls: "TOP 5 OI GAINERS (CALLS)",
    topoiloserscalls: "TOP 5 OI LOSERS (CALLS)",
    topoigainersputs: "TOP 5 OI GAINERS (PUTS)",
    topoilosersputs: "TOP 5 OI LOSERS (PUTS)",
};

export const summaryEquityWidgetsKeys = {
    eq_topgainers: "eq_topgainers",
    eq_toplosers: "eq_toplosers",
    eq_topmostactive: "eq_topmostactive",
    eq_topvolumegainers: "eq_topvolumegainers",
    eq_top52high: "eq_top52high",
    eq_top52low: "eq_top52low",
    eq_topgapup: "eq_topgapup",
    eq_topgapdown: "eq_topgapdown",
};

export const summaryFutureWidgetsKeys = {
    fut_toppricegainers: "fut_toppricegainers",
    fut_toppricelosers: "fut_toppricelosers",
    fut_topvolumegainers: "fut_topvolumegainers",
    fut_topactivefutures: "fut_topactivefutures",
    fut_topoigainers: "fut_topoigainers",
    fut_topoilosers: "fut_topoilosers",
    fut_toppremium: "fut_toppremium",
    fut_topdiscount: "fut_topdiscount",
};

export const summaryOptionsWidgetsKeys = {
    opt_topmostactivecalls: "opt_topmostactivecalls",
    opt_topvolumegainerscalls: "opt_topvolumegainerscalls",
    opt_topmostactiveputs: "opt_topmostactiveputs",
    opt_topvolumegainersputs: "opt_topvolumegainersputs",
    opt_topoigainerscalls: "opt_topoigainerscalls",
    opt_topoiloserscalls: "opt_topoiloserscalls",
    opt_topoigainersputs: "opt_topoigainersputs",
    opt_topoilosersputs: "opt_topoilosersputs",
};

export const WatchlistColumns = [
    { id: '1', name: "Symbol", editable: false, default: true, apikey: "companyName", activefor: ["eq", "fut", "opt"], type: 'string' },
    { id: '2', name: "Exchange", editable: false, default: true, apikey: "exc", activefor: ["eq", "fut", "opt"], type: 'string' },
    { id: '3', name: "%Change", editable: false, default: true, apikey: "chngPer", activefor: ["eq", "fut", "opt"], type: 'num' },
    { id: '4', name: "Bid Qty", editable: true, default: true, apikey: "bidQty", activefor: ["eq", "fut", "opt"], type: 'num' },
    { id: '5', name: "Bid Price", editable: true, default: true, apikey: "bidPrice", activefor: ["eq", "fut", "opt"], type: 'num' },
    { id: '6', name: "Offer Qty", editable: true, default: true, apikey: "askQty", activefor: ["eq", "fut", "opt"], type: 'num' },
    { id: '7', name: "Offer Price", editable: true, default: true, apikey: "askPrice", activefor: ["eq", "fut", "opt"], type: 'num' },
    { id: '58', name: "Volume", editable: true, apikey: "vol", activefor: ["eq", "fut", "opt"], type: 'num' },
    { id: '8', name: "LTP", editable: false, default: true, apikey: "ltp", activefor: ["eq", "fut", "opt"], type: 'num' },
    { id: '9', name: "LTQ", editable: false, default: true, apikey: "ltq", activefor: ["eq", "fut", "opt"], type: 'num' },
    //{ id: '10', name: "TBQ", editable: true, default: true, apikey: "totBuyQty", activefor: ["eq", "fut", "opt"], type: 'num' },
    //{ id: '11', name: "TSQ", editable: false, default: true, apikey: "totSellQty", activefor: ["eq", "fut", "opt"], type: 'num' },
    { id: '12', name: "Last Updated Time", editable: false, default: true, apikey: "lUpdatedTime", activefor: ["eq", "fut", "opt"], type: 'date' },
    { id: '13', name: "ATP", editable: false, default: true, apikey: "avgTradedPrice", activefor: ["eq", "fut", "opt"], type: 'num' },
    { id: '14', name: "Change", editable: false, default: true, apikey: "chng", activefor: ["eq", "fut", "opt"], type: 'num' },
    //{ id: '15', name: "Daily Price Range", editable: true, default: true, apikey: "dpr", activefor: ["eq", "fut", "opt"], type: 'hyphen' },
    { id: '16', name: "Chart", editable: false, default: true, apikey: "chart", activefor: ["eq", "fut", "opt"], type: 'hyphen' },
    { id: '17', name: "Last Trade Time", editable: false, default: true, apikey: "lTradedTime", activefor: ["eq", "fut", "opt"], type: 'date' },
    { id: '18', name: "OI", editable: true, apikey: "openInterest", activefor: ["fut", "opt"], type: 'num' },
    // { id: '19', name: "Events", editable: true, apikey: "events", activefor: ["eq", "fut"] },
    // { id: '20', name: "5D Avg Volume", editable: true, apikey: "avgVolume", activefor: ["eq"] },
    //      { id: '21', name: "Strike Price", editable: true, apikey: "strike", activefor: ["fut", "opt"], type: 'num' },
    // { id: '22', name: "OI Change %", editable: true, apikey: "oiPer", activefor: ["fut", "opt"] },
    // { id: '23', name: "Type of Security", editable: true, apikey: "ts", activefor: ["eq", "fut", "opt"] },
    // { id: '24', name: "Market Cap", editable: true, apikey: "marketCap", activefor: ["eq"] },
    // { id: '25', name: "Delta", editable: true, apikey: "delta", activefor: ["opt"] },
    //      { id: '26', name: "High OI", editable: true, apikey: "highOi", activefor: ["fut", "opt"], type: 'num' },
    //      { id: '27', name: "Sector Series", editable: true, apikey: "ss", activefor: ["eq", "fut", "opt"], type: 'string' },
    // { id: '28', name: "Tech Alert", editable: true, apikey: "techAlert", activefor: ["eq", "fut"] },
    // { id: '29', name: "Gamma", editable: true, apikey: "gamma", activefor: ["opt"] },
    //      { id: '30', name: "Low OI", editable: true, apikey: "lowOi", activefor: ["fut", "opt"], type: 'num' },
    // { id: '31', name: "1M change %", editable: true, apikey: "1mchngPer", activefor: ["eq"] },
    // { id: '32', name: "Delivery", editable: true, apikey: "delivery", activefor: ["eq"] },
    // { id: '33', name: "Rho", editable: true, apikey: "rho", activefor: ["opt"] },
    // { id: '34', name: "Life Time High", editable: true, apikey: "lth", activefor: ["eq"] },
    // { id: '35', name: "3M chang e%", editable: true, apikey: "3mchngPer", activefor: ["eq"] },
    // { id: '36', name: "% Delivery", editable: true, apikey: "deliveryPer", activefor: ["eq"] },
    // { id: '37', name: "Theta", editable: true, apikey: "theta", activefor: ["opt"] },
    // { id: '38', name: "Life Time Low", editable: true, apikey: "ltl", activefor: ["eq"] },
    // { id: '39', name: "6M change %", editable: true, apikey: "6mchngPer", activefor: ["eq"] },
    // { id: '40', name: "Quote", editable: true, apikey: "quote", activefor: ["eq", "fut"] },
    // { id: '41', name: "Vega", editable: true, apikey: "vega", activefor: ["opt"] },
    //      { id: '42', name: "52 Week High", editable: true, apikey: "fifty2weekHigh", activefor: ["eq"], type: 'num' },
    // { id: '43', name: "Circuit Limit", editable: true, apikey: "cl", activefor: ["eq", "opt"] },
    //      { id: '44', name: "Expiry", editable: true, apikey: "expiry", activefor: ["fut", "opt"], type: 'date' },
    // { id: '45', name: "Market Type", editable: true, apikey: "marketType", activefor: ["eq", "fut", "opt"] },
    //      { id: '46', name: "52 Week Low", editable: true, apikey: "fifty2weekLOW", activefor: ["eq"], type: 'num' },
    // { id: '47', name: "YTD % Change", editable: true, apikey: "ytdchngPer", activefor: ["eq"] },
    // { id: '48', name: "CoC/Premium", editable: true, apikey: "premium", activefor: ["fut"] },
    //      { id: '49', name: "Open", editable: true, apikey: "openPrice", activefor: ["eq", "fut", "opt"], type: 'num' },
    // { id: '50', name: "1 Week Uptrend", editable: true, apikey: "wu", activefor: ["eq"] },
    // { id: '51', name: "% Volume", editable: true, apikey: "volPer", activefor: ["eq", "fut", "opt"] },
    // { id: '52', name: "Spot", editable: true, apikey: "spot", activefor: ["fut", "opt"] },
    //      { id: '53', name: "High", editable: true, apikey: "highPrice", activefor: ["eq", "fut", "opt"], type: 'num' },
    // { id: '54', name: "1 Week Downtrend", editable: true, apikey: "wd", activefor: ["eq"] },
    // { id: '55', name: "Spread/Impact Cost", editable: true, apikey: "spread", activefor: ["eq", "fut", "opt"] },
    // { id: '56', name: "Day's Build up", editable: true, apikey: "dbu", activefor: ["eq", "fut", "opt"] },
    //      { id: '57', name: "Low", editable: true, apikey: "lowPrice", activefor: ["eq", "fut", "opt"], type: 'num' },

    // { id: '59', name: "Spread %", editable: true, apikey: "spreadPer", activefor: ["eq", "fut", "opt"] },
    //      { id: '60', name: "Option Type", editable: true, apikey: "optType", activefor: ["opt"], type: 'string' },
    //      { id: '61', name: "Close", editable: true, apikey: "closePrice", activefor: ["eq", "fut", "opt"], type: 'num' }
];

export const MyTradescolumnCurrency = [
    { id: '1', name: "SYMBOL", editable: false, default: true, apikey: "displaySym" },
    { id: '2', name: "ORDER", editable: false, default: true, apikey: "ordAction" },
    { id: '3', name: "EXCH", editable: false, default: true, apikey: "exc" },
    { id: '4', name: "ORDER TYPE", editable: true, default: true, apikey: "ordType" },
    { id: '5', name: "TRADED PRICE", editable: true, default: true, apikey: "tradedPrice" },
    { id: '6', name: "TOTAL QTY", editable: true, default: true, apikey: "tradedQty" },
    { id: '7', name: "PRODUCT TYPE", editable: true, default: true, apikey: "prdType" },
    { id: '8', name: "ORDER REFERENCE NUMBER", editable: true, default: true, apikey: "orderId" },
    { id: '9', name: "EXCH ORDER NO", editable: true, default: true, apikey: "exchOrderId" },
    { id: '10', name: "TRADE NO", editable: true, default: true, apikey: "exchTradeId" },
    { id: '11', name: "EXCH TRADE TIME", editable: true, default: true, apikey: "tradeTime" },
];
export const MyTradescolumn = [
    { id: '1', name: "SYMBOL", editable: false, default: true, apikey: "displaySym" },
    { id: '2', name: "ORDER", editable: false, default: true, apikey: "ordAction" },
    { id: '3', name: "EXCH", editable: false, default: true, apikey: "exc" },
    { id: '4', name: "ORDER TYPE", editable: true, default: true, apikey: "ordType" },
    { id: '5', name: "TRADED PRICE", editable: true, default: true, apikey: "tradedPrice" },
    { id: '6', name: "TOTAL QTY", editable: true, default: true, apikey: "tradedQty" },
    { id: '7', name: "PRODUCT TYPE", editable: true, default: true, apikey: "prdType" },
    { id: '8', name: "ORDER REFERENCE NUMBER", editable: true, default: true, apikey: "orderId" },
    { id: '9', name: "EXCH ORDER NO", editable: true, default: true, apikey: "exchOrderId" },
    { id: '10', name: "TRADE NO", editable: true, default: true, apikey: "exchTradeId" },
    { id: '11', name: "EXCH TRADE TIME", editable: true, default: true, apikey: "tradeTime" },
]
export const MyBasketcolumn =[
    { id: '1', name: "SYMBOL", editable: false, default: true, apikey: "TR_SYMBOL" },
    { id: '2', name: "EXCHANGE", editable: false, default: true, apikey: "exc" },
    { id: '3', name: "BUY/SELL", editable: false, default: true, apikey: "BUY_SELL_IND" },
    { id: '4', name: "PRODUCT", editable: true, default: true, apikey: "SEGMENT_TYPE" },
    { id: '5', name: "ORDER PRICE", editable: true, default: true, apikey: "LIMIT_PRICE" },
    { id: '6', name: "QTY/LOT", editable: true, default: true, apikey: "QTY" },
    { id: '7', name: "DISC./DISC.LOT", editable: true, default: true, apikey: "DISC_QTY" },
    { id: '8', name: "TRIGGER PRICE SL", editable: true, default: true, apikey: "TRIGGER_PRICE" },
    { id: '9', name: "GOOD TILL", editable: true, default: true, apikey: "DAY_IOC_ORD" },
    { id: '10', name: "ORDER TYPE", editable: true, default: true, apikey: "ORD_TYPE" }
]

export const ReportSTTColumn = [
    { id: '1', name: "CLIENT CODE", editable: false, default: true, apikey: "displaySym" },
    { id: '2', name: "CODE OF TRANSACTION", editable: false, default: true, apikey: "ordAction" },
    { id: '3', name: "VALUE OF TRANSACTIONS ENTERED INTO DURING THE FINANCIAL YEAR", editable: false, default: true, apikey: "exc" },
    { id: '4', name: "TOTAL SECURITIES TRANSACTION TAX COLLECTED FROM THE ASSESSEE DURING THE FINANCIAL YEAR", editable: true, default: true, apikey: "ordType" },
    { id: '5', name: "VALUE OF TRANSACTION(INCLUDING INV VALUE GIVEN IN COLUMN 3)ENTERED INTO IN THE COURSE OF BUSINESS BY THE ASSESSEE", editable: true, default: true, apikey: "tradedPrice" },
    { id: '6', name: "SECURITIES TRANSACTION TAX COLLECTED ON VALUE OF TRANSACTIONS GIVEN IN COLUMN 4", editable: true, default: true, apikey: "tradedQty" },
]
//===================================================
// Module keys (used to identify components in UI)

export const ModuleKeys = {
    NIFTY: 'NIFTY 50',
    SENSEX: 'SENSEX',

    ALL: "ALL",
    TOP_GAINERS: "TOP_GAINERS",
    TOP_LOOSERS: "TOP_LOOSERS",
    RESEARCH: "RESEARCH",
    PENDING_ORDER: "PENDING_ORDER",
    WORLD_MARKET: "WORLD_MARKET",
    HOT_NEWS: "HOT_NEWS",

    MSALL: "ALL",
    GRADES: "GRADES",
    MARKET_COMMENTARY: 'MARKET_COMMENTARY',
    BUZZING_STOCKS: "BUZZING_STOCKS",
    WEEK_HIGH: "WEEK_HIGH",
    WEEK_LOW: "WEEK_LOW",
    MOST_ACTIVE: "MOST_ACTIVE",

    PIVOT_WATCH: 'PIVOT_WATCH',
    VWAP_WATCH: 'VWAP_WATCH',
    HIGHLOW_WATCH: 'HIGHLOW_WATCH',
    VOLUME_WATCH: 'VOLUME_WATCH',

    CURALL: 'ALL',
    CURRENCY_FUTURES: 'CURRENCY_FUTURES',
    CURRENCY_OPTION: 'CURRENCY_OPTION',
    CURRENCY_SPOT: 'CURRENCY_SPOT',

    COMALL: 'ALL',
    TOP_COMMODITY_FUTURES: 'TOP_COMMODITY_FUTURES',
    TOP_COMMODITY_OPTION: 'TOP_COMMODITY_OPTION',
    COMMODITY_SPOT: 'COMMODITY_SPOT',

    FNO_PULSE: "FNO_PULSE",
    PUT_CALL_RATIO_OI: "PUT_CALL_RATIO_OI",
    PUT_CALL_RATIO_VOLUME: "PUT_CALL_RATIO_VOLUME",
    ROLLOVER: "ROLLOVER",
    TOP_GAINERS_FUT: "TOP_GAINERS_FUT",
    TOP_LOSERS_FUT: "TOP_LOSERS_FUT",
    MOST_ACTIVE_FUT: "MOST_ACTIVE_FUT",
    MOST_ACTIVE_OPT: "MOST_ACTIVE_OPT",
    IV_GAINERS: "IV_GAINERS",
    IV_LOSERS: "IV_LOSERS",

    WatchlistView1: "WatchlistView1",
    WatchlistView2: "WatchlistView2",

    COMPANY_NEWS: 'COMPANY_NEWS',
    SECTOR_NEWS: 'SECTOR_NEWS',
    INTERNATIONAL_NEWS: 'INTERNATIONAL_NEWS',
    ECONOMIC_NEWS: 'ECONOMIC_NEWS',
    CORPORATE_NEWS: 'CORPORATE_NEWS',
    DISCUSSION_CLOUD: 'DISCUSSION_CLOUD',
    CURRENCY_NEWS: 'CURRENCY_NEWS',
    COMMODITY_NEWS: 'COMMODITY_NEWS',
    DERIVATIVE_NEWS: 'DERIVATIVE_NEWS',

    SCRIP_TECHNICALS: 'TECHNICALS',
    MARKET_DEPTH: 'MARKET_DEPTH',
    SCRIP_OPTION_CHAIN: "SCRIP_OPTION_CHAIN",

    CHART_1: 'CHART_1',
    CHART_2: 'CHART_2',
    CHART_3: 'CHART_3',
    CHART_4: 'CHART_4',

    FOOTER_WIDGET: 'FOOTER_WIDGET'
};

//===================================================
// Order Types

export const TradeActions = {
    BUY: "BUY",
    SELL: "SELL"
}


//===================================================
// file export types

export const fileExportTypes = {
    EXCEL: "EXCEL",
    CSV: "CSV"
}

//===================================================
// used to check the api app level info msg
export const reqAppInfoMsg = {
}


//===================================================
// Footer Actions (used to identify which button in footer is clicked)

export const FooterActions = {
    WATCHLIST: 'WATCHLIST',
    PLACE_ORDER: 'PLACE_ORDER',
    MY_ORDERS: 'MY_ORDERS',
    MY_TRADES: 'MY_TRADES',
    NET_POSITION: 'NET_POSITION',
    MARGIN: 'MARGIN',
    MYTRADINGPLAN: 'MYTRADINGPLAN',
    EDIS_REQUESTS: 'CANCEL EDIS'
};

//===================================================
// Common Messages

export const CommonErrorMessages = {
    NO_DATA: "No Data found",
    UNABLE_LOAD: "Unable to load"
}


//===================================================
// Contact Details

export const contactDetails = {
    CUSTOMER_CARE: "022 61446300",
    DIAL_AND_TRADE: "022-71907190",
    HELPDESK_EMAIL: "helpdesk@dhani.com",
}

//report types
export const reportTypes = {
    CG_SUMMARY: "CG_SUMMARY",
    CG_DATEWISE: "CG_DATEWISE"
}

//chart id's
export const chartId = {
    chartContainer1: '1',
    chartContainer2: '2',
    chartContainer3: '3',
    chartContainer4: '4'
}

//financial year
export const financialYear = [{ name: "2024-2025" },{ name: "2023-2024" },{ name: "2022-2023" },{ name: "2021-2022" },{ name: "2020-2021" },{ name: "2019-2020" }, { name: "2018-2019" }];

//segments for reports
export const reportSegments = [{ name: "SECURITIES", key: "SECURITIES" }, { name: "COMMODITY", key: "COMMODITY" }]
//export const reportSegments = [{ name: "SECURITIES", key: "SECURITIES" }];

export const holdingsDropDownOptions = [{ name: 'Holdings', value: 'H' }, { name: 'Negative Holdings', value: 'N' }, { name: 'Zero Holdings', value: 'Z' },{ name: 'All', value: 'A' }];

export const UserOptionsALLPermit = [{ name: 'My Details' }, { name: 'DP Holdings' }, { name: 'Fund Transfer' },{ name: 'My Trading Plan' }, { name: 'Settings' }, { name: 'Logout' }]; //{name:'Limit Against Holding'}DST-724

export const UserOptionsCommPermit = [{ name: 'My Details' }, { name: 'DP Holdings' }, { name: 'Fund Transfer' }, { name: 'Settings' }, { name: 'Logout' }];

