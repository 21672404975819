import { UrlExtensions, BaseUrl, localStorageKeys } from '../../common/constants';
import { httpPost } from '../base/api';
import {getItemByKey} from '../../../src/common/utils';
import {encryptUrl,serialiseForm,generateRandomString} from '../../../src/actions/base/api';

// ====================== pledge-unpledge  request =================

export const getNTPledgeUnpledge = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.NTPLEDGEUNPLEDGEDATA;
    httpPost(target, requestData)
    .then(function (response) {
        console.log("inside pledge request method");
        console.log('request succeeded with JSON response', response);
        responseCallback(response);
        // return response;
    }).catch(function (error) {
        console.log('request failed', error);
        errorCallback(error);
    });
}


// ==pledge unpledge submit request==
export const getNTPledgeUnpledgeSubmit = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.PLEDGESUBMITREQUEST;
    httpPost(target, requestData)
    .then(function (response) {
        console.log("inside pledge request method");
        console.log('request succeeded with JSON response', response);
        responseCallback(response);
        // return response;
    }).catch(function (error) {
        console.log('request failed', error);
        errorCallback(error);
    });
}

// ==pledge CDSL submit request==
export const submitNTPledgeCDSLRequest = function (requestData, responseCallback, errorCallback) {
    console.log("shruti --- submit margin pledge");
    console.log(requestData);
    var url =  BaseUrl + UrlExtensions.PLEDGE_CDSL_URL;

    var form = document.createElement("form");
    form.setAttribute("method", "POST");
    // form.setAttribute("target","_blank");
    form.setAttribute("action", url , "scrollbars=no,menubar=no,height=600,width=800,resizable=yes,toolbar=no,status=no");
    form.setAttribute("id","marginPledgeCDSL");
    var hiddenField = document.createElement("input");
    var hiddenField1 = document.createElement("input");
    var hiddenField2 = document.createElement("input");
    var hiddenField3 = document.createElement("input");
    var hiddenField4 = document.createElement("input");
    var hiddenField5 = document.createElement("input");
    // var hiddenField6 = document.createElement("input");
    hiddenField.setAttribute('type',"text");
    hiddenField1.setAttribute('type',"text");
    hiddenField2.setAttribute('type',"text");
    hiddenField3.setAttribute('type',"text");
    hiddenField4.setAttribute('type',"text");
    hiddenField5.setAttribute('type',"text");
    // hiddenField6.setAttribute('type',"text");
    hiddenField.setAttribute("name", "clientId")
    hiddenField1.setAttribute("name", "source")
    hiddenField2.setAttribute("name", "demat")
    hiddenField3.setAttribute("name", "appKey")
    hiddenField4.setAttribute("name", "reqType")
    hiddenField5.setAttribute("name", "secDtls")
    // hiddenField6.setAttribute("name", "source")
    hiddenField.setAttribute("value", requestData.request.clientId)
    hiddenField1.setAttribute("value",  requestData.request.source)
    hiddenField2.setAttribute("value", requestData.request.demat)
    hiddenField3.setAttribute("value", requestData.request.appkey)
    hiddenField4.setAttribute("value", requestData.request.reqType)
    hiddenField5.setAttribute("value", requestData.request.secDtls)
    form.appendChild(hiddenField);
    form.appendChild(hiddenField1);
    form.appendChild(hiddenField2);
    form.appendChild(hiddenField3);
    form.appendChild(hiddenField4);
    form.appendChild(hiddenField5);
    document.body.appendChild(form);
    // form.submit();
    var targetPledgeForm = serialiseForm("marginPledgeCDSL").trim();
    var key = generateRandomString(32);
    console.log(url+"?source=W&"+encryptUrl(targetPledgeForm, key));
    window.location.href = url+"?source=W&"+encryptUrl(targetPledgeForm, key)+"@@"+key;
}

// ==pledge NSDL submit request==
export const submitNTPledgeNSDLRequest = function (requestData, responseCallback, errorCallback) {
    console.log("shruti --- submit margin pledge");
    console.log(requestData);
    var url =  BaseUrl + UrlExtensions.PLEDGE_NSDL_URL;

    var form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("target","_blank");
    form.setAttribute("action", url , "scrollbars=no,menubar=no,height=600,width=800,resizable=yes,toolbar=no,status=no");
    form.setAttribute("id","marginPledgeNSDL");
    var hiddenField = document.createElement("input");
    var hiddenField1 = document.createElement("input");
    var hiddenField2 = document.createElement("input");
    var hiddenField3 = document.createElement("input");
    var hiddenField4 = document.createElement("input");
    var hiddenField5 = document.createElement("input");
    var hiddenField6 = document.createElement("input");
    var hiddenField7 = document.createElement("input");
    var hiddenField8 = document.createElement("input");
    var hiddenField9 = document.createElement("input");
    var hiddenField10 = document.createElement("input");
    hiddenField.setAttribute('type',"text");
    hiddenField1.setAttribute('type',"text");
    hiddenField2.setAttribute('type',"text");
    hiddenField3.setAttribute('type',"text");
    hiddenField4.setAttribute('type',"text");
    hiddenField5.setAttribute('type',"text");
    hiddenField6.setAttribute('type',"text");
    hiddenField7.setAttribute('type',"text");
    hiddenField8.setAttribute('type',"text");
    hiddenField9.setAttribute('type',"text");
    hiddenField10.setAttribute('type',"text");
    hiddenField.setAttribute("name", "dpId")
    hiddenField1.setAttribute("name", "clientId")
    hiddenField2.setAttribute("name", "appKey")
    hiddenField3.setAttribute("name", "appName")
    hiddenField4.setAttribute("name", "noOfSecurities")
    hiddenField5.setAttribute("name", "requestType")
    hiddenField6.setAttribute("name", "source")
    hiddenField7.setAttribute("name", "secDtls")
    hiddenField8.setAttribute("name", "requestReference")
    hiddenField9.setAttribute("name", "dematAcc")
    hiddenField10.setAttribute("name", "symbol")
    hiddenField.setAttribute("value", requestData.request.dpId)
    hiddenField1.setAttribute("value", requestData.request.clientId)
    hiddenField2.setAttribute("value",  requestData.request.appkey)
    hiddenField3.setAttribute("value", requestData.request.appName)
    hiddenField4.setAttribute("value", requestData.request.noOfSecurities)
    hiddenField5.setAttribute("value", requestData.request.requestType)
    hiddenField6.setAttribute("value", requestData.request.source)
    hiddenField7.setAttribute("value", requestData.request.secDtls)
    hiddenField8.setAttribute("value", requestData.request.requestReference)
    hiddenField9.setAttribute("value", requestData.request.dematAcc)
    hiddenField10.setAttribute("value", requestData.request.symbol)

    form.appendChild(hiddenField);
    form.appendChild(hiddenField1);
    form.appendChild(hiddenField2);
    form.appendChild(hiddenField3);
    form.appendChild(hiddenField4);
    form.appendChild(hiddenField5);
    form.appendChild(hiddenField6);
    form.appendChild(hiddenField7);
    form.appendChild(hiddenField8);
    form.appendChild(hiddenField9);
    form.appendChild(hiddenField10);
    document.body.appendChild(form);
    form.submit();
    // var targetPledgeForm = serialiseForm().trim();
    // var key = generateRandomString();
    // console.log(url+"?source=W&"+encryptUrl(targetPledgeForm, key));
    // window.location.href = url+"?source=W&"+encryptUrl(targetPledgeForm, key)+"@@"+key;

    // window.location.href = "https://uatots.indiabulls.com/pledge/finalResult.html";
}


// ==pledge check margin pledge NSDL response==
export const checkStatusForNTMarginPledgeNSDLRequest = function (requestData, responseCallback, errorCallback) {
    var target = BaseUrl + UrlExtensions.PLEDGE_NSDL_REQUEST_FOR;
    httpPost(target, requestData)
    .then(function (response) {
        console.log("inside pledge request method");
        console.log('request succeeded with JSON response', response);
        responseCallback(response);
        // return response;
    }).catch(function (error) {
        console.log('request failed', error);
        errorCallback(error);
    });
}