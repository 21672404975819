import React, { Component } from 'react';
// import Dialog from "../../controls/Dialog";
import {getNTPledgeUnpledgeSubmit} from '../../actions/NTPoaPledge/NTPledgeUnpledgeRequest'
import { commonDialog, toggleDialog } from '../../actions/common'
import { connect } from 'react-redux';
import OrderNumberInput from '../../controls/OrderNumberInput'
import { numericSort, stringSort} from '../../common/utils';


class NTUnpledgeMobileData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            InDpshowDialog: false,
            chkbox:false,
            symbolArr:[],
            message:'Are you sure to submit Un-Pledge request.',
            inputtext:'100',
            isquantitydisabled: false,
            maxQuantity: undefined,
            lotsize: 1,
            inuValueChange:'',
            FreeQty: '',
            ChangedQty: '',
            data:this.props.data,
            modelBox:false,
            SucessMessage:false,
            sortAsc: {
                sym_sort: false, qty_sort: false, avgprice_sort: false,
                purvalue_sort: false, currmktprice_sort: false, currmktvalue_sort: false, todayplperc_sort: false,
                todaypl_sort: false, notionalpl_sort: false, notionalplperc_sort: false, realised_sort:false
            },
            sortColumnId:this.props.sortColumnId,
            sortType:this.props.sortType,
            sortDataType:this.props.sortDataType
        }
        this.onSortClicked = this.onSortClicked.bind(this);
        this.SubmitClickPledge = this.SubmitClickPledge.bind(this);
        this.quantityOnChange = this.quantityOnChange.bind(this);
    }


    componentWillReceiveProps(nextProps){
        this.setState({data:nextProps.data});
        var temp=nextProps.data,
        sortType=nextProps.sortType;

        switch (nextProps.sortDataType)
        {
            case "STRING":
                temp = stringSort(temp, nextProps.sortColumnId, sortType);
                break;
            case "NUMBER":
                temp = numericSort(temp, nextProps.sortColumnId, sortType);
                break;

            default: break;

        }
        this.setState({data:temp});

    }

    SubmitClickPledge(){
        console.log("into unpleadege shruti........")
        this.SubmitPositivePopup();

    }

    symbolSelectionChanged(e) {
        this.setState({
            chkbox:true
        })
        if (e.target.checked) {
            var quantites = document.getElementsByClassName("qtyChange");
                var qtyArr = [];
                        for(var i=0; i < quantites.length; i++){
                            qtyArr.push({"id":quantites[i].id.split("qty")[1], "qty" : quantites[i].getElementsByTagName("input")[0].value});
                }
                var changeQuntyIndx = qtyArr.findIndex(item => (item.id === JSON.parse(e.target.getAttribute('data-tag')).isin));

            this.state.symbolArr.push({
                symbol:  JSON.parse(e.target.getAttribute('data-tag')).symbol,
                isin:  JSON.parse(e.target.getAttribute('data-tag')).isin,
                segment: JSON.parse(e.target.getAttribute('data-tag')).segment,
                qty: qtyArr[changeQuntyIndx].qty
            }
            );
        }
        else {
            this.state.symbolArr.pop(JSON.parse(e.target.getAttribute('data-tag')));
            {this.state.symbolArr.length > 0 ? console.log("heloo true condition") :
            this.setState({
                chkbox:false
            })
            }
        }
    }
    // on positive click
    SubmitPositivePopup(){

        var requestData = {
            request: {
                data: {
                    symbolArr : this.state.symbolArr,
                    demat: this.props.dematAcc,
                    req_type: this.props.selecttab,
                    source: this.props.source

                }
            }
        };
        getNTPledgeUnpledgeSubmit(requestData, this.parsePledgeSubmitResponse.bind(this), this.parsePledgeSubmitError.bind(this));
    }
    parsePledgeSubmitResponse(responseData){
        this.setState({
           message: responseData.response.data.MESSAGE,
           InDpshowDialog:false
        },()=>{
            this.setState({
                SucessMessage:true,
                ErrorMessage: this.state.message
            })

        })
    }
    parsePledgeSubmitError(){
        this.setState({
            inProgress: false,
            error: "somthing went wrong"
        }, () => {
            this.setState({
                SucessMessage:true,
                ErrorMessage: this.state.error
            })
        })
    }
    // req qty editable

    quantityOnChange(pledgeqty, isin, inputqty ){
         this.setState({
             FreeQty: pledgeqty,
             ChangedQty: inputqty
         })
        if(parseInt(inputqty) > parseInt(pledgeqty)){
            this.setState({
                modelBox:true,
                ErrorMessage: "Quantity can not be greater than "+ parseInt(pledgeqty),
            })
        }
        else{
            this.setState({
                inuValueChange: inputqty
            },()=>{

            });
            if(this.state.symbolArr.length !== 0 && this.state.symbolArr.length !== null){
                var ArrayIndex = this.state.symbolArr.findIndex(item => (item.isin === isin));
                if (ArrayIndex !== -1) {
                    this.state.symbolArr[ArrayIndex].qty = inputqty;
                }
            }
        }

    }

    onSortClicked(e) {
        var sortTemp = this.state.sortAsc;
        var sortcolumn="";
        var sortDataType="";
        switch (e.currentTarget.id) {
            case "sym_sort":
                sortTemp.sym_sort = !sortTemp.sym_sort;
                sortDataType="STRING";
                sortcolumn="symbol";
                break;
            case "isin_sort":
                sortTemp.isin_sort = !sortTemp.isin_sort;
                sortDataType="STRING";
                sortcolumn="isin";
                break;
            case "qty_sort1":
                sortTemp.qty_sort1 = !sortTemp.qty_sort1;
                sortDataType="NUMBER";
                sortcolumn="toUnPledgeQty";
                break;
            case "pled_sort1":
                sortTemp.pled_sort1 = !sortTemp.pled_sort1;
                sortDataType="NUMBER";
                sortcolumn="freeQty";
                break;
            case "chek_sort":
                sortTemp.chek_sort = !sortTemp.chek_sort;
                sortDataType="STRING";
                sortcolumn="isin";
                break;
            default: break;

        }
        this.props.callback(sortcolumn, sortTemp[e.currentTarget.id]? "ASC" : "DSC", sortDataType);
    }

    onNegativeClick(){
        this.setState({
            modelBox:false
        },()=>{
            this.props.requestNTPledgeUnpledge();
        })
    }

    onSucessClick(){
        this.setState({
            SucessMessage:false
        },()=>{
            this.props.requestNTPledgeUnpledge();
        })
    }

    render() {
        return (
            <div className="fillheight pledgeDataHeight">
                <div className="row contentpanel dpholdings-header" style={{ display: 'flex', color:'#000',paddingTop:'35px' }}>
                    <div className="col-4">Select</div>
                    <div className="col-4" id="sym_sort" onClick={(e) => this.onSortClicked(e)}>
                    <img src='assets/svg/sort-mobile.png' className="fa-sort" alt="sort"></img>
                        Symbol</div>

                    <div className="col-4 text-right" id="qty_sort1" onClick={(e) => this.onSortClicked(e)}>
                    <img src='assets/svg/sort-mobile.png' className="fa-sort" alt="sort"></img>
                        QTY</div>

                </div>
                <div className="dp-scr">
                {this.props.data.map((data, index) => < div style={{ background: index < 3 && '#fff' }}>
                    <div className="row contentpanel" style={{ display: 'flex', paddingTop: 10, paddingBottom: 10 }}>
                        <div className="col-4"> <input type="checkbox"  data-tag={JSON.stringify(data)} name={data.symbol} onChange={this.symbolSelectionChanged.bind(this)} /> </div>
                        <div className="col-4" style={{color:'#000'}}>{data.symbol}</div>
                        <div className="col-4 text-right pledgeinput mobile-input-qty">
                        <span class="qtyChange" id={"qty" + data.isin}>
                        <OrderNumberInput
                                onChange={this.quantityOnChange.bind(this, data.freeQty, data.isin )}
                                defaultValue={data.freeQty}
                                isnumber={true}
                                tabIndex="1"
                                isDisabled={this.state.isquantitydisabled}
                                incrementvalue={this.state.lotsize}
                                minValue={this.state.lotsize}
                                maxValue={this.state.maxQuantity}
                            />
                            </span>
                        </div>
                    </div>
                    <div className="hdivider" style={{ marginBottom: 0 }} />
                    </div>
                )}
                    <div className="row">
                        <div className="col-12 text-center" style={{marginTop:'15px'}}>
                           {this.state.chkbox == false  ?
                           <button type="button" className="btn btnColor btn-primary form-control" disabled style={{opacity: '0.5', cursor:'no-drop'}}
                           tabIndex="8">Submit for UN-Pledge Request</button>
                           :
                           <button type="button" className="btn btnColor btn-primary form-control"
                           tabIndex="8" onClick={this.SubmitClickPledge.bind(this)}>Submit for UN-Pledge Request</button>
                            }

                        </div>
                    </div>

                </div>
                {this.state.modelBox === true ?
                <div class="modal fade dialog-show in" role="dialog" style={{ display: "block" }}>
                        <div class="modal-dialog appdialogmodal mobileErrorBox" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Message</h5>
                                </div>
                                <div class="modal-body">
                                    <p style={{color:'#000'}}>{this.state.ErrorMessage}</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="text-center btn btn-success" data-dismiss="modal" onClick={this.onNegativeClick.bind(this)}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :''
                }
                {this.state.SucessMessage === true ?
                <div class="modal fade dialog-show in" role="dialog" style={{ display: "block" }}>
                        <div class="modal-dialog appdialogmodal mobileErrorBox" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">Message</h5>
                                </div>
                                <div class="modal-body">
                                    <p style={{color:'#000'}}>{this.state.ErrorMessage}</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="text-center btn btn-success" data-dismiss="modal" onClick={this.onSucessClick.bind(this)}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :''
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownprops) => {
    return {
        commonDialog: (payload) => commonDialog(dispatch, payload),
        toggleDialog: (payload) => toggleDialog(payload, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NTUnpledgeMobileData);