import React, { Component } from 'react';
import { exchangeNTPledgeUnpledge } from '../../actions/NTExchangePledge/NTExchangePledgeUnpledgeRequest'
import { commonDialog, toggleDialog } from '../../actions/common'
import { connect } from 'react-redux';
import OrderNumberInput from '../../controls/OrderNumberInput'
import PropTypes from 'prop-types';
import { numericSort, stringSort,getItemByKey } from '../../common/utils';
import {localStorageKeys} from '../../common/constants'



class NTExchangePledgeData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            InDpshowDialog: false,
            symbolArr: [],
            chkbox: false,
            message: 'Are you sure to submit Pledge request.',
            error: '',
            FreeQty: '',
            ChangedQty: 0,
            isCheck: false,
            data: this.props.data,
            chekbox: false,
            exErr:this.props.exErr,

            sortAsc: {
                sym_sort: false, qty_sort: false, avgprice_sort: false,
                purvalue_sort: false, currmktprice_sort: false, currmktvalue_sort: false, todayplperc_sort: false,
                todaypl_sort: false, notionalpl_sort: false, notionalplperc_sort: false, realised_sort: false
            },
            sortColumnId: this.props.sortColumnId,
            sortType: this.props.sortType,
            sortDataType: this.props.sortDataType,
            pledgeTimer: '',
            color:'',
            bgColor:'#0c1029',
            height:'90%',
            bgSegColor:"#262a30"

        }
        this.onSortClicked = this.onSortClicked.bind(this);
        this.SubmitClickPledge = this.SubmitClickPledge.bind(this);
        this.exQuantityOnChange = this.exQuantityOnChange.bind(this);
    }

     componentDidMount() {
        window.$('.panel-collapse').on('show.bs.collapse', function () {
            window.$(this).parent().find('.hot-news-arrow').addClass('up');
        });

        window.$('.panel-collapse').on('hide.bs.collapse', function () {
            window.$(this).parent().find('.hot-news-arrow').removeClass('up');
        });

        document.getElementById('submitRequest').disabled = true;
        if(this.props.isPIB){
            this.setState({bgColor:'#fff', color:'black !important', height:'81%',bgSegColor:"#fff"});
            let div = document.getElementsByClassName("ipo-accordion");

            window.setTimeout(() => {
                if(div.length > 0){

                    for(var i =0; i <= div.length; i++){
                        div[i].style = "background:white !important;height:130px!important;"
                    }
                    document.getElementsByClassName("dpholdings-header")[0].style = "color:black";
                }
                let modal = document.getElementsByClassName("modal-content")[0];
                if(modal !== undefined){
                    modal.style= "background:white !important;color:black"
                }

            }, 50);
        }else{
            this.setState({color:"#fff"})
        }
    }



    componentWillReceiveProps(nextProps) {
        var temp = nextProps.data,
            sortType = nextProps.sortType;

        switch (nextProps.sortDataType) {
            case "STRING":
                temp = stringSort(temp, nextProps.sortColumnId, sortType);
                break;
            case "NUMBER":
                temp = numericSort(temp, nextProps.sortColumnId, sortType);
                break;

            default: break;

        }
        this.setState({ data: temp });

    }

    SubmitClickPledge() {
        console.log(this.state.symbolArr);
        if (this.state.symbolArr.length !== 0 ) {
            for (var i = 0; i < this.state.symbolArr.length; i++) {
                let count = 0;
                if(this.state.symbolArr[i].segments.length > 0){
                for(var j=0; j < this.state.symbolArr[i].segments.length; j++){
                    count += parseInt(this.state.symbolArr[i].segments[j].qty);
                }
            }
                if (count == 0) {
                var payload = {
                            showDialog: true, closeBtn: true, message: "Quantity can not be 0", header: "Message",
                        }
                        this.props.commonDialog(payload);
                        this.checkPibModal();
                        return false;
                    }
                }
                    var payload = {
                        showDialog: true, closeBtn: false, message: this.state.message, header: "Message",
                        buttons: [
                            {
                                name: "YES", action: function () {
                                    this.SubmitPositivePopup();
                                    this.props.toggleDialog(false);
                                }.bind(this)
                            },
                            {
                                name: "NO", action: function () {
                                    this.props.toggleDialog(false);
                                }.bind(this)
                            }
                        ]
                    }
                    this.props.commonDialog(payload);
                    this.checkPibModal();
                }

    }
    // submit api========
    SubmitPositivePopup() {
        let appKey = getItemByKey(localStorageKeys.PIB_APP_KEY) !== "" ? JSON.parse(getItemByKey(localStorageKeys.PIB_APP_KEY)) : "";

            var requestData = {
                request: {
                    data: {
                        symbols: this.state.symbolArr,
                        reqType: "EPLEDGE",
                        appKey:appKey ,
                        userId: window.sessionStorage.getItem("userId")
                    }
                }
            };
            exchangeNTPledgeUnpledge(requestData, this.parsePledgeSubmitResponse.bind(this), this.parsePledgeSubmitError.bind(this));
    }


    parsePledgeSubmitResponse(responseData) {
        this.setState({
            message: responseData.response.data.status,
            InDpshowDialog: false
        }, () => {
            var payload = {
                showDialog: true, closeBtn: false, message: "SUCCESS", header: "Message",
                buttons: [
                    {
                        name: "ok", action: function () {
                            window.sessionStorage.setItem("exPledgeReload", "true");
                            this.props.requestNTPledgeUnpledge();

                            if(this.props.isPIB){
                                document.getElementsByClassName("nav tab-bar highlight-above")[0].children[2].click();
                            }else{
                                document.getElementsByClassName("nav tab-bar highlight-above")[0].children[0].click();

                            }
                            this.props.toggleDialog(false);
                        }.bind(this)
                    }
                ]
            }
            this.props.commonDialog(payload);
            this.checkPibModal();
        })
    }

    parsePledgeSubmitError(error) {
           this.setState({
                inProgress: false,
                error: "Somthing went wrong"
            }, () => {
                var payload = {
                    showDialog: true, closeBtn: false, message: this.state.error, header: "Message",
                    buttons: [
                        {
                            name: "ok", action: function () {
                                this.props.toggleDialog(false);
                            }.bind(this)
                        }
                    ]
                }

                this.props.commonDialog(payload);
                this.checkPibModal();
            })
    }


    exQuantityOnChange(id, totalqty, isin, inputqty){
        this.checkIspibIntput();
       let symbolIdforSeg = id.split("segQty")[1];
       var exSegquantity = document.getElementById(symbolIdforSeg).getElementsByClassName("input-number");
       let allSegCount = 0;
       for(var i= 0; i < exSegquantity.length; i++){
        allSegCount += parseInt(exSegquantity[i].value);
       }
       console.log("allSegCount"+allSegCount)
        this.setState({
            FreeQty: (parseInt(totalqty)-allSegCount),
            ChangedQty: parseInt(inputqty)
        })
        //this.updateSymbolArray(symbolIdforSeg);
        var symbolArrayIndex = this.state.symbolArr.findIndex(item => (item.isin === isin));
        if (symbolArrayIndex !== -1) {

            if (parseInt(allSegCount) > parseInt(totalqty)) {
                document.getElementById('submitRequest').disabled = true;
                var payload = {
                    showDialog: true, closeBtn: true, message: "Your total qty is " + allSegCount + " qty can not be greater than " + totalqty, header: "Message",
                }
               this.props.commonDialog(payload);
               this.checkPibModal();
            } else if(!((totalqty-allSegCount)>=0)){
                document.getElementById('submitRequest').disabled = true;
                var payload = {
                    showDialog: true, closeBtn: true, message: "Your total qty is " + totalqty + " qty can not be greater than " + allSegCount, header: "Message",
                }
                this.props.commonDialog(payload);
                this.checkPibModal();

            }  else {
                document.getElementById('submitRequest').disabled = false;

            }
            if (this.state.symbolArr.length !== 0 && this.state.symbolArr.length !== null) {
                var ArrayIndex = this.state.symbolArr.findIndex(item => (item.isin === isin));

                if (ArrayIndex !== -1) {
                    this.state.symbolArr[ArrayIndex].segments = this.checkSegmentQuantity(symbolIdforSeg);
                }

                document.getElementById("freeQty-"+symbolIdforSeg).innerHTML = (allSegCount)+"/"+totalqty;
            }
        }

    }


    symbolSelectionChanged(e, qty) {
        console.log(e);
        this.checkIspibIntput();
        this.setState({
            chkbox: true,
        })
        var exSegquantity = document.getElementById(JSON.parse(e.target.getAttribute('data-tag')).segmentsId).getElementsByClassName("input-number");

        if (e.target.checked) {
            for(var i = 0; i < exSegquantity.length; i++){
                exSegquantity[i].disabled = false;
                exSegquantity[i].style = "cursor:pointer";
            }
            var checkArrIndex = this.state.symbolArr.findIndex(item => (item.isin === JSON.parse(e.target.getAttribute('data-tag')).isin));

            if (checkArrIndex === -1) {
                this.state.symbolArr.push({
                    symbol: JSON.parse(e.target.getAttribute('data-tag')).symbol,
                    isin: JSON.parse(e.target.getAttribute('data-tag')).isin,
                    segments: this.checkSegmentQuantity(JSON.parse(e.target.getAttribute('data-tag')).segmentsId),
                    demat: JSON.parse(e.target.getAttribute('data-tag')).demat
                }
                );
            }
            if (this.state.symbolArr.length !== 0) {
                document.getElementById('submitRequest').disabled = false;
            }
        }
        else {
            var checkArrIndex = this.state.symbolArr.findIndex(item => (item.isin === JSON.parse(e.target.getAttribute('data-tag')).isin));

            if (checkArrIndex !== -1) {
                this.state.symbolArr.splice(checkArrIndex, 1);
            }
            if (this.state.symbolArr.length === 0) {
                document.getElementById('submitRequest').disabled = true;
            }
            for(var i = 0; i < exSegquantity.length; i++){
                exSegquantity[i].disabled = true;
                exSegquantity[i].style = "cursor:not-allowed";
            }
            this.setState({
                chkbox: false
            })
        }
    }

    checkPibModal(){
        if(this.props.isPIB){
            window.setTimeout(() => {
                let div =  document.getElementsByClassName("modal-content");
                if(div.length > 0){
                    for(var i = 0; i <div.length; i++){
                        div[i].style = "background:white !important;color:black!important";
                    }
                }
            }, 50);
        }
    }

    checkIsSelected(symbolSegments){
        console.log("arrow" +symbolSegments );
        //let symbolId = "symSegments"+symbolSegments;
        var symbolsCheckbox = document.getElementsByClassName('pledgeCheckbox');
      //  console.log(symbolId);
        var ischecked = false;
        if(this.state.symbolArr.length === 0){
            var payload = {
                showDialog: true, closeBtn: true, message: "Please select symbol to proceed further", header: "Message",
            }

           this.props.commonDialog(payload);
           this.checkPibModal();
        }else {
            for(var i =0; i < symbolsCheckbox.length; i++){
                if(symbolsCheckbox[i].id === "checkbx"+symbolSegments ){
                    ischecked = symbolsCheckbox[i].checked;
                }

            }
            if(!ischecked){
                var payload = {
                    showDialog: true, closeBtn: true, message: "Please select symbol to proceed further", header: "Message",
                }

               this.props.commonDialog(payload);
               this.checkPibModal();
            }
        }
        if(!ischecked){
            this.setState({chkbox : true});
            var exSegquantity = document.getElementById(symbolSegments).getElementsByClassName("input-number");
            for(var i = 0; i < exSegquantity.length; i++){
                 exSegquantity[i].value = 0;
                exSegquantity[i].disabled = true;
                exSegquantity[i].style = "cursor:not-allowed";
            }
        }
        this.checkIspibIntput();

    }

    checkIspibIntput(){
        if(this.props.isPIB){
            let div = document.getElementsByClassName("input-number");
            let segRow = document.getElementsByClassName("segRow");
            if (segRow.length > 0) {
                for (var i = 0; i < segRow.length; i++) {
                    segRow[i].style = "color:black !important";
                }
            }
            if (div.length > 0) {
                for (var i = 0; i < div.length; i++) {
                    div[i].style = "color:black !important";
                }
            }
        }
    }

    checkSegmentQuantity(segmentsId){
        let segArr = [];
        var exSegquantity = document.getElementById(segmentsId).getElementsByClassName("input-number");
        for(var i = 0; i < exSegquantity.length; i++){
            if(exSegquantity[i].value > 0){
                segArr.push({name:exSegquantity[i].parentElement.parentElement.className, quantity: exSegquantity[i].value});
            }
        }
            return segArr;
    }

    handleChange(e) {
    }
    // shorting on symbol=====

    onSortClicked(e) {
        var sortTemp = this.state.sortAsc;
        var sortcolumn = "";
        var sortDataType = "";
        switch (e.currentTarget.id) {
            case "sym_sort":
                sortTemp.sym_sort = !sortTemp.sym_sort;
                sortDataType = "STRING";
                sortcolumn = "symbol";
                break;
            case "isin_sort":
                sortTemp.isin_sort = !sortTemp.isin_sort;
                sortDataType = "STRING";
                sortcolumn = "isin";
                break;
            case "qty_sort":
                sortTemp.qty_sort = !sortTemp.qty_sort;
                sortDataType = "NUMBER";
                sortcolumn = "freeQty";
                break;

            case "sta_sort":
                sortTemp.sta_sort = !sortTemp.sta_sort;
                sortDataType = "STRING";
                sortcolumn = "request";
                break;
            default: break;

        }
        this.props.callback(sortcolumn, sortTemp[e.currentTarget.id] ? "ASC" : "DSC", sortDataType);
    }


    render() {

        return (
            <div id = "exPledge" style={{height:this.state.height}}>
                <div className="row segRow contentpanel dpholdings-header" style={{ display: 'flex',color:this.state.color }}>
                    <div className="col-1">Select</div>
                    <div className="col text-right" id="sym_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        Symbol</div>
                    <div className="col text-right mobile_hidden" id="isin_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        ISIN</div>

                    <div className="col text-right mobile_hidden" id="qty_sort" onClick={(e) => this.onSortClicked(e)}>
                        <img src='assets/svg/sort.svg' className="fa-sort" alt="sort"></img>
                        Exchange Pledge Qty</div>
                    <div className="col text-right mobile_hidden" >
                        Segment</div>
                </div>
                <div className="dp-scr ">
                    {this.state.data.map((data, index) => < div style={{ background: this.state.bgColor,color:this.state.color }}>
                        <div className="row " style={{color:this.state.color}}>
                            <div class="col-lg-12 col-md-12 col-sm-12 contentpanel" style={{ display: 'flex', paddingTop: 10, paddingBottom: 10 }}   id={'#pledgeRow' + data.symbol}>
                            <div className="col-1">
                                {/* <input type="checkbox" class="pledgeCheckbox" defaultChecked={this.state.isCheck} data-tag={JSON.stringify(data)} id={data.isin} onChange={this.symbolSelectionChanged.bind(this)} /> */}
                                <input type="checkbox" class="pledgeCheckbox" defaultChecked={this.state.isCheck}
                                    data-tag={JSON.stringify({ 'symbol': data.symbol, 'isin': data.isin, 'segmentsId': index+"-"+data.demat+"-"+data.isin, 'freeQty':data.freeQty,'demat': data.demat })}
                                    id={"checkbx"+index+"-"+data.demat+"-"+data.isin} onChange={this.symbolSelectionChanged.bind(this)} name={data.toPledgeQty} />
                            </div>
                            <div className="col text-right">{data.symbol}</div>
                            <div className="col text-right mobile_hidden">{data.isin}</div>
                            <div className="col text-right" id={"freeQty"+"-"+index+"-"+data.demat+"-"+data.isin}> 0/{data.freeQty}  </div>
                            <div className="col text-right " >
                                <a className="contentpanel" onClick={this.checkIsSelected.bind(this, index+"-"+data.demat+"-"+data.isin )} >
                                    <i ref={"arrow"+ data.symbol} className="fa fa-angle-down hot-news-arrow"   style={{ color: this.state.color }} data-toggle="collapse" data-target={'#pledgeRow' + data.symbol} aria-expanded="true" aria-controls={'pledgeRow' + data.symbol}></i>
                                </a>
                            </div>
                           </div>
                           <div id={"pledgeRow" +data.symbol} className="col-lg-12 col-md-12 col-sm-12 panel-collapse collapsing" aria-labelledby={'pledgeRow' + data.symbol}>
                                    <div className="jumbotron panel-body ipo-accordion" style={{height: '100%'}}>
                                    <div className=" exQtyChange funds-title" id={index+"-"+data.demat+"-"+data.isin}>
                                    {this.state.exErr.map((exdata, index1) => < div style={{ background:this.state.bgSegColor,color:this.state.color }}>
                                                   <div class="row segRow">
                                                <div className="col text-right">
                                                {exdata}
                                                </div>
                                                <div className="col-3 text-right pledgeinput mobile_hidden">
                                                    {/* <input type="text" value={data.toPledgeQty} /> */}
                                                    <span className={exdata}>
                                                        <OrderNumberInput
                                                            onChange={this.exQuantityOnChange.bind(this, (index1+"segQty"+index+"-"+data.demat+"-"+data.isin), data.freeQty, data.isin)}
                                                            defaultValue={0}
                                                            isnumber={true}
                                                            tabIndex="1"
                                                            style={{color:this.state.color}}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div className="hdivider" style={{ marginBottom: 0 }} />
                    </div>
                    )}

                    <div className="row">
                        <div className="col-12 text-center" style={{margin:'10px,0'}}>

                            <button type="button" id="submitRequest" className="btn-footer order-place active-buy" style={{position: 'fixed',display: 'block',width: '12%',height: '6.9%',right: '45%',bottom: '13%','z-index': '2'}}
                                tabIndex="8" onClick={this.SubmitClickPledge.bind(this)}>EXCHANGE PLEDGE</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

NTExchangePledgeData.protoTypes = {
    callback: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, ownprops) => {
    return {
        commonDialog: (payload) => commonDialog(dispatch, payload),
        toggleDialog: (payload) => toggleDialog(payload, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NTExchangePledgeData);
