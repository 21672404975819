import React, { Component } from 'react';
import { ssoService } from '../../actions/login/preloginrequests';
import '../../common/socketwrapper';
import { progressBar } from '../../controls/progress'
import { Redirect, Route, Switch} from 'react-router-dom';
import PortfolioTracker from '../portfolioTracker/PortfolioTracker';
import IPO from '../products/IPO';
import MyTradingPlan from '../MyTradingPlan/MyTradingPlan';
import {setCookie, setItemByKey} from '../../common/utils'
import MarketOpen from '../prelogin/MarketOpen'
import PoaPledgeMobile from '../PoaPledge/PoaPledgeMobile';
import PortfolioTrackerDion from '../portfolioTracker/PortfolioTrackerDion';

class PortfolioTrackerPIBSSO extends Component {

    constructor(props) {
        super(props);
        this.state={
            loginStatus:'0',
            error:'',
            userId:'',
            source:'',
            appkey:''
        }
    }

    componentWillMount(){
        var requestData = {
            request: {
                data: {
                    data: this.props.data
                }
            }
        }
        this.setState({ inProgress: true, error: '', data: [] });
        ssoService(requestData, this.ssoSuccessCallback.bind(this), this.ssoFailedCallback.bind(this));
    }
    
    ssoSuccessCallback(responseData) {
        if(responseData.response.data.result=="SUCCESS" && ["PortfolioTrackerSSO","PortfolioTrackerSSODion"].includes(this.props.redirectUrl))
        {   
            this.setState({inProgress:false, loginStatus:'1', userId:responseData.response.data.userId});
            window.socketEvents.disconnect();
            window.socketEvents.oninit();
        }
        else if(responseData.response.data.result=="SUCCESS" && this.props.redirectUrl=="MyTradingPlan")
        {
            this.setState({inProgress:false, loginStatus:'1', userId:responseData.response.data.userId});
        }

        else if(responseData.response.data.result=="SUCCESS" && this.props.redirectUrl=="MarketStatus")
        {
            this.setState({inProgress:false, loginStatus:'1', userId:responseData.response.data.userId});
        }
        else if(responseData.response.data.result=="SUCCESS" && this.props.redirectUrl=="IPO")
        {
            this.setState({inProgress:false, loginStatus:'1', userId:responseData.response.data.userId});
        }
        else if(responseData.response.data.result=="SUCCESS" && this.props.redirectUrl=="IPOOrder")
        {
            this.setState({inProgress:false, loginStatus:'1', userId:responseData.response.data.userId});
        }
        else if(responseData.response.data.result=="SUCCESS" && this.props.redirectUrl=="PledgeRequestDataParam")
        {
            this.setState({inProgress:false, loginStatus:'1', userId:responseData.response.data.userId, source:responseData.response.data.source, appkey : responseData.response.data.appkey });
        }
    }

    ssoFailedCallback(error) { 
        this.setState({
            inProgress: false,
            error: error.message,
        });      

        setCookie('REDIRECT', null, 0);
        setCookie('DATA_PARAM', null, 0);
        setItemByKey('DATA_PARAM', null);
    }

    render() {

        var errorHolder = (
            <div className="error-holder" style={{ marginTop: "5px" }}>
                <span> {this.state.error} </span>
            </div>
        );

                
            return (
                <div className={this.props.redirectUrl == "MarketStatus" ? 'mobile-app' : 'fillheight'}>
                {
                    this.state.error ? 
                    errorHolder :
                    this.state.inProgress ? progressBar() : 
                    this.state.loginStatus=='1' ?
                    <Switch>              
                        <Route path="/PortfolioTrackerSSO" exact component={() => <PortfolioTracker  userId={this.state.userId} />}></Route>
                        <Route path="/PortfolioTrackerSSODion" exact component={() => <PortfolioTrackerDion  userId={this.state.userId} />}></Route>
                        <Route path="/MyTradingPlan" exact component={() => <MyTradingPlan flag='0' userId={this.state.userId}/>}></Route>
                        <Route path="/MarketStatus" exact component={() => <MarketOpen userId={this.state.userId}/>}></Route>
                        <Route path="/IPO" exact component={() => <IPO userId={this.state.userId}/>}></Route>
                        <Route path="/IPOOrder" exact component={() => <IPO userId={this.state.userId} selectedsegment="Order Book" reqsegment="orderbook"/>}></Route>
                        <Route path="/PoaPledge" exact component={() => <PoaPledgeMobile userId={this.state.userId} source={this.state.source} appkey={this.state.appkey}/>}></Route>
                        {
                            this.props.redirectUrl == "PortfolioTrackerSSO" ? <Redirect to="/PortfolioTrackerSSO" />:
                            this.props.redirectUrl == "PortfolioTrackerSSODion" ? <Redirect to="/PortfolioTrackerSSODion" />:
                            this.props.redirectUrl == "MarketStatus"? <Redirect to="/MarketStatus" />:
                            this.props.redirectUrl == "IPO"? <Redirect to="/IPO" />:
                            this.props.redirectUrl == "IPOOrder"? <Redirect to="/IPOOrder" />:
                            this.props.redirectUrl == "PledgeRequestDataParam" ? <Redirect to="/PoaPledge" /> :
                            this.props.redirectUrl == "MyTradingPlan"? <Redirect to="/MyTradingPlan" /> :
                            <Redirect to="/base/login" />
                        }
                    </Switch>: 
                    <Redirect to="/base/login" /> 
                } 
                </div>
            );
           
        }

}
export default PortfolioTrackerPIBSSO;